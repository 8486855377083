// Chakra imports
import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import InputField from "components/fields/InputField";
import React from "react";
export default function Settings() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  return (
    <FormControl>
      <Card mb='20px'>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            EMS Details2
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can put esm Details2
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <Flex direction='column'>
            <SimpleGrid
              mb='20px'
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: "20px", xl: "20px" }}>
              <InputField
                mb='0px'
                me='30px'
                id='info1'
                label='info1'
                placeholder='eg. 99'
              />
              <InputField
                mb='0px'
                id='info2'
                label='info2'
                placeholder='eg. 4030120241'
              />
            </SimpleGrid>
            <Flex direction='column'>
              <FormLabel
                ms='10px'
                htmlFor='currency'
                fontSize='sm'
                color={textColorPrimary}
                fontWeight='bold'
                _hover={{ cursor: "pointer" }}>
                Industry
              </FormLabel>
              <Select
                fontSize='sm'
                id='Industry'
                variant='main'
                h='44px'
                maxh='44px'
                me='20px'
                defaultValue='usd'>
                <option value='usd'>green</option>
                <option value='eur'>blue</option>
                <option value='gbp'>red</option>
              </Select>
            </Flex>
          </Flex>
          {/*<TagsField*/}
          {/*  id='description'*/}
          {/*  label='Description'*/}
          {/*  mb='0px'*/}
          {/*  h='140px'*/}
          {/*  placeholderTags={[*/}
          {/*    {*/}
          {/*      name: "chair",*/}
          {/*      id: 1,*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "furniture",*/}
          {/*      id: 2,*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "elegant",*/}
          {/*      id: 3,*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*/>*/}
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
