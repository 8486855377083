import React, {useState, useEffect} from 'react';

import Card from "components/card/Card";

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,Center,
} from '@chakra-ui/react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import {

    Box, FormControl, FormLabel, Input, Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,Grid,
    SimpleGrid, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Icon
} from "@chakra-ui/react";
import './App.css';
import {isAuth} from "../../../../components/auth";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement, // add this line
  Title,
  Tooltip,
  Legend
);
const labels = ['Energy', 'Environmental', 'Financial', 'NEB', 'Total Score'];


export const EsmDetails = ({data, someTitleProp}) => {
    console.log(data)
    const options = {
        responsive: true,
        plugins: {
            legend: {

                position: 'top',
            },
            title: {
                display: true,
                text: 'Esm Score per Assessment Pillar',
            },
        },
    };


    const chartData = {
    labels,
    datasets: [
      {
        label: 'Score',
        data: [data?.energy_aggregate,data?.environmental_aggregate,data?.financial_aggregate,data?.NEB_aggregate,data?.totalEsmScore],
        backgroundColor: [ '#94a464', '#3f7690', '#89a8b4', '#bfc9b6','#9fbbb2',],
      },
      
    ],
  };
      return (
        <>
            <Center bg='blue.50'  color='black'>
                ESM MAIN INFORMATION
            </Center>
            <SimpleGrid  columns={[3, null, 2]}   spacingX='60px' spacingY='20px'>

                <Box w="100%" maxHeight="500px"  minHeight="400px" borderWidth="1px" borderRadius="lg"  p="5">
                    ESM Title:
                    <Box height='80px' overflow="auto">

                        <Card class="card_main_info" bg="#89a8b4" overflow="auto">
                            <Text fontSize="sm" overflow="auto">{data?.title}</Text>
                        </Card>
                    </Box>
                    ESM Description:
                    <Box maxHeight="180px" overflow="auto">
                        <Card bg="#89a8b4" >
                            <Text fontSize="sm" >{data?.description}</Text>
                        </Card>

                    </Box>
                    <br></br>
                    <SimpleGrid columns={4} spacing={1}>

                    <Box  >
                        Industrial Sector:
                        <Card bg="#89a8b4">
                            <Text fontSize="sm" >{data?.industry?.title}</Text>
                        </Card>
                    </Box>

                    <Box  >
                        Technology Group:
                        <Card bg="#89a8b4">
                            <Text fontSize="sm" >{data?.technologyGroup?.title}</Text>
                        </Card>
                    </Box>

                    <Box  >
                             Country:
                        <Card bg="#89a8b4">
                            <Text fontSize="sm" >{data?.country?.name ?? "None"}</Text>
                        </Card>
                    </Box>
                    <Box  >
                        Status of Implementation:
                        <Card bg="#89a8b4">
                            <Text fontSize="sm" >{data?.statusOfImplementation}</Text>
                        </Card>
                    </Box>
                    </SimpleGrid>
                </Box>
                <Box w="100%" h="400px" borderWidth="1px" borderRadius="lg" overflow="hidden" p="5">
                    <Bar options={options} data={chartData}/>
                </Box>


            </SimpleGrid>
            <Center bg='blue.50' h='20px' color='black'>
               ESM KPIs
            </Center>
            <SimpleGrid spacingX='60px' spacingY='90px'>
             <Box>
            <Grid templateColumns="repeat(8, 2fr)" gap={3}>
                <Box w="100%" >
                    <Card><Stat>
                        <StatLabel>KPI 1 - PES (TOE/Year)</StatLabel>
                        <StatNumber>
                            {typeof data?.PES_KPI1 === 'number'
                                ? data?.PES_KPI1.toFixed(3)
                                : "N/A"}
                        </StatNumber>

                    </Stat>
                    </Card>
                </Box>
                <Box w="100%" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 2 - Energy Intensity Reduction (%)</StatLabel>
                            <StatNumber>
                                {typeof data?.energy_intensity_reduction_KPI2 === 'number'
                                    ? (data?.energy_intensity_reduction_KPI2* 100).toFixed(3)
                                    : "N/A"}
                            </StatNumber>

                        </Stat>
                    </Card>
                </Box>
                 <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 3 - Consumption Reduction per Product Unit (TOE/Year/Product Unit)</StatLabel>
                            <StatNumber>
                                {typeof data?.consumption_reduction_per_product_KPI3 === 'number'
                                    ? data?.consumption_reduction_per_product_KPI3.toFixed(6)
                                    : "N/A"}
                            </StatNumber>

                        </Stat>
                    </Card>
                </Box>
                 <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 4 - Cost of Energy Savings (k€/TOE/Year)</StatLabel>
                            <StatNumber>
                                {typeof data?.cost_of_energy_savings_KPI4 === 'number'
                                    ? (data?.cost_of_energy_savings_KPI4).toFixed(3)
                                    : "N/A"}
                            </StatNumber>

                        </Stat>
                    </Card>
                </Box>
                  <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 5 - Carbon Savings (tCO2/Year)</StatLabel>
                            <StatNumber>
                                {typeof data.carbon_savings_KPI5 === 'number'
                                    ? data.carbon_savings_KPI5.toFixed(3)
                                    : "N/A"}
                            </StatNumber>

                        </Stat>
                    </Card>
                </Box>
                  <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KP6 - Cost of Carbon Savings (k€/tCO2/Year)</StatLabel>
                            <StatNumber>
                                {typeof data?.cost_of_carbon_savings_KPI6 === 'number'
                                    ? (data?.cost_of_carbon_savings_KPI6).toFixed(3)
                                    : "N/A"}
                            </StatNumber>

                        </Stat>
                    </Card>
                </Box>
                 <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 7 - Carbon Reduction per product unit (tCO2/Year/Product Unit)</StatLabel>
                            <StatNumber>
                                {typeof data?.carbon_reduction_per_product_KPI7 === 'number'
                                    ? data?.carbon_reduction_per_product_KPI7.toFixed(5)
                                    : "N/A"}
                            </StatNumber>
                        </Stat>
                    </Card>
                </Box>
                <Box w="100%" h="10" >
                    <Card >
                        <Stat>
                            <StatLabel>KPI 8 - Renewable Energy use Gain (%)</StatLabel>
                            <StatNumber>
                                {typeof data?.renewable_energy_use_gain_KPI8 === 'number'
                                    ? (data?.renewable_energy_use_gain_KPI8* 100).toFixed(3)
                                    : "N/A"}
                            </StatNumber>
                        </Stat>
                    </Card>
                </Box>
                </Grid>
            </Box>
             <Box>
            <Grid templateColumns="repeat(8, 2fr)" gap={6}>

                <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 9 - Simple Payback Period (Years)</StatLabel>
                            <StatNumber>
                                {typeof data?.simple_payback_period_KPI9 === 'number'
                                    ? data?.simple_payback_period_KPI9.toFixed(3)
                                    : "N/A"}
                            </StatNumber>
                        </Stat>
                    </Card>
                </Box>
                 <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 10 - Net Present Value (k€)</StatLabel>
                            <StatNumber>
                                {typeof data?.net_present_value_KPI10 === 'number'
                                    ? (data?.net_present_value_KPI10).toFixed(3)
                                    : "N/A"}
                            </StatNumber>
                        </Stat>
                    </Card>
                </Box>
                 <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 11 - Benefit Cost Ratio</StatLabel>
                            <StatNumber>
                                {typeof data?.benefit_cost_ratio_KPI11 === 'number'
                                    ? data?.benefit_cost_ratio_KPI11.toFixed(3)
                                    : "N/A"}
                            </StatNumber>
                        </Stat>
                    </Card>
                </Box>
                  <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 12 - Internal Rate of Return (%)</StatLabel>
                            <StatNumber>
                                {typeof data?.internal_rate_of_return_KPI12 === 'number'
                                    ? (data?.internal_rate_of_return_KPI12* 100).toFixed(3)
                                    : "N/A"}
                            </StatNumber>
                        </Stat>
                    </Card>
                </Box>
                  <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 13 - Productivity</StatLabel>
                            <StatNumber>{data?.productivity_KPI13}</StatNumber>
                        </Stat>
                    </Card>
                </Box>
                 <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 14 - O&M</StatLabel>
                            <StatNumber>{data?.Q_M_KPI14}</StatNumber>
                        </Stat>
                    </Card>
                </Box>
                <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 15 - Work Envirnment</StatLabel>
                            <StatNumber>{data?.work_environment_KPI15}</StatNumber>
                        </Stat>
                    </Card>
                </Box>
                <Box w="100%" h="10" >
                    <Card>
                        <Stat>
                            <StatLabel>KPI 16 - Other NEBs</StatLabel>
                            <StatNumber>{data?.other_NEBs_KPI16}</StatNumber>
                        </Stat>
                    </Card>
                </Box>
                </Grid>
            </Box>


            <div>
                <div height="50=5px"></div>
            <Center bg='blue.50'  color='black'>
                ESM OTHER INFORMATION
            </Center>
            <Table class="my-table" variant='striped'  size='sm' wordBreak="break-all">

                        <Thead>

                            <Tr>
                                <Th>ESM DATA TYPE</Th>
                                <Th>ESM DATA</Th>
                                <Th>ESM DATA TYPE</Th>
                                <Th>ESM DATA</Th>
                                <Th>ESM DATA TYPE</Th>
                                <Th>ESM DATA</Th>
                            </Tr>
                        </Thead>
                        <Tbody>

                            <Tr>
                                <Td style={{backgroundColor:"#d3cdcd"}}>A2M Partner</Td>
                                <Td > {data?.a2mPartner || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#d3cdcd"}}>Reference Year</Td>
                                <Td>{data?.referenceYear || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#d3cdcd"}}>NACE CODE</Td>
                                <Td>{data?.naceCode || 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#d3cdcd"}}>Data refer to one production site or entire company</Td>
                                <Td>{data?.prodSiteData || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#d3cdcd"}}>Capital Expenditure (k€)</Td>
                                <Td>{data?.capitalExpenditure || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#d3cdcd"}}>Company's Primary Energy Consumption (TOE)</Td>
                                <Td> {data?.companyEnConsumption !== null
                                    ? data.companyEnConsumption.toFixed(2)
                                    : 'N/A'
                                }</Td>

                            </Tr>
                            <Tr>


                                <Td style={{backgroundColor:"#d3cdcd"}}>Company's Production Output (Tonnes of product)</Td>
                                <Td>{data?.companyProdOutput || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#d3cdcd"}}>Company's Size</Td>
                                <Td>{data?.companySize || 'N/A'}</Td>
                                <Td style={{backgroundColor:"#d3cdcd"}}>Company's Turnover (k€)</Td>
                                <Td>{data?.companyTurnover !== null
                                    ? (data.companyTurnover/1000).toFixed(3)
                                    : 'N/A'
                                }</Td>

                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#cce0b9"}}>Comments or Links</Td>
                                <Td>{data?.commentsLinks || 'N/A'}</Td>

                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#cce0b9"}}>Energy Carrier 1 - {data?.energyCarrier1 || 'N/A'} </Td>
                                <Td>Primary Consumption Before (TOE):{data?.energyCarrierPrimConsBefore1!==null ? data?.energyCarrierPrimConsBefore1.toFixed(3) : 'N/A'}  After:{data?.energyCarrierPrimConsAfter1!==null ?data?.energyCarrierPrimConsAfter1.toFixed(3) : 'N/A'}</Td>
                                <Td style={{backgroundColor:"#cce0b9"}}>Energy Carrier 2 - {data?.energyCarrier2 || 'N/A'} </Td>
                                <Td>Primary Consumption Before (TOE):{data?.energyCarrierPrimConsBefore2!==null ? data?.energyCarrierPrimConsBefore2.toFixed(3) : 'N/A'}  After:{data?.energyCarrierPrimConsAfter2!==null ?data?.energyCarrierPrimConsAfter2.toFixed(3) : 'N/A'}</Td>

                                <Td style={{backgroundColor:"#cce0b9"}}>Energy Carrier 3 - {data?.energyCarrier3 || 'N/A'} </Td>
                                <Td>Primary Consumption Before (TOE):{data?.energyCarrierPrimConsBefore3!==null ? data?.energyCarrierPrimConsBefore3.toFixed(3) : 'N/A'}  After:{data?.energyCarrierPrimConsAfter3!==null ?data?.energyCarrierPrimConsAfter3.toFixed(3) : 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#cce0b9"}}>Energy Carrier 4 - {data?.energyCarrier4 || 'N/A'} </Td>
                                <Td>Primary Consumption Before (TOE):{data?.energyCarrierPrimConsBefore4!==null ? data?.energyCarrierPrimConsBefore4.toFixed(3) : 'N/A'}  After:{data?.energyCarrierPrimConsAfter4!==null ?data?.energyCarrierPrimConsAfter4.toFixed(3) : 'N/A'}</Td>

                                <Td style={{backgroundColor:"#cce0b9"}}>Energy Carrier 5 - {data?.energyCarrier5 || 'N/A'} </Td>
                                <Td>Primary Consumption Before (TOE):{data?.energyCarrierPrimConsBefore5!==null ? data?.energyCarrierPrimConsBefore5.toFixed(3) : 'N/A'}  After:{data?.energyCarrierPrimConsAfter5!==null ?data?.energyCarrierPrimConsAfter5.toFixed(3) : 'N/A'}</Td>

                                <Td style={{backgroundColor:"#cce0b9"}}>ESM's Lifetime (Years)</Td>
                                <Td>{data?.lifetime || 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#8abccb"}}>Total Primary Energy Saved (TOE)</Td>
                                <Td> {data?.totalPrimaryEnergySaved !== null
                                    ? data.totalPrimaryEnergySaved.toFixed(2)
                                    : 'N/A'
                                }</Td>

                                <Td style={{backgroundColor:"#8abccb"}}>Renewable Energy Use Before (TOE)</Td>
                                <Td>{data?.renewableEnergyUseBefore || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#8abccb"}}>Renewable Energy Use After (TOE)</Td>
                                <Td>{data?.renewableEnergyUseAfter || 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#f3e5c7"}}>Simple Payback Period (Years)</Td>
                                <Td>{typeof data?.simplePaybackPeriod === 'number'
                                    ? data?.simplePaybackPeriod.toFixed(3)
                                    : "N/A"}

                                </Td>

                                <Td style={{backgroundColor:"#f3e5c7"}}>Total Avoided Energy Cost (k€)</Td>
                                <Td>
                                    {typeof data?.total_avoided_energy_cost_eur === 'number'
                                    ? (data?.total_avoided_energy_cost_eur/1000).toFixed(3)
                                    : "N/A"}
                                </Td>

                                <Td style={{backgroundColor:"#f3e5c7"}}>Percentage of Capex Subsidy (%)</Td>
                                <Td> {data?.percentage_capex_subsidy !== null
                                    ? data.percentage_capex_subsidy.toFixed(2)
                                    : 'N/A'
                                }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#f3e5c7"}}>Discount Rate Used (%)</Td>
                                <Td>
                                    {typeof data?.discount_rate_used === 'number'
                                    ? data?.discount_rate_used.toFixed(3)
                                    : "N/A"}
                                </Td>

                                <Td style={{backgroundColor:"#f3e5c7"}}>Benefit Cost Ratio</Td>
                                <Td>{typeof data?.benefit_cost_ratio === 'number'
                                    ? data?.benefit_cost_ratio.toFixed(3)
                                    : "N/A"}
                                </Td>

                                <Td style={{backgroundColor:"#f3e5c7"}}>Internal Rate of Return (%)</Td>
                                <Td>
                                    {typeof data?.internal_rate_of_return === 'number'
                                    ? data?.internal_rate_of_return.toFixed(3)
                                    : "N/A"}
                                </Td>
                            </Tr>
                            <Tr>

                                <Td style={{backgroundColor:"#638ba4"}}>Waste Reduction (%)</Td>
                                <Td> {data?.waste_reduction !== null
                                    ? data.waste_reduction.toFixed(2)
                                    : 'N/A'
                                }</Td>

                                <Td style={{backgroundColor:"#6b88a2"}}>Carbon Savings (tCO2/Year)</Td>
                                <Td> {data?.carbon_savings !== null
                                    ? data.carbon_savings.toFixed(2)
                                    : 'N/A'
                                }</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#9fbbb2"}}>Improved product quality</Td>
                                <Td>{data?.improved_product_quality.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Raw material use reduction</Td>
                                <Td>{data?.raw_material_use_reduction.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Improved Production Efficiency</Td>
                                <Td>{data?.improved_production_efficiency.toString() || 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#9fbbb2"}}>Improved Equipment Performance</Td>
                                <Td>{data?.improved_equipment_performance.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Reduced Need for Maintenance</Td>
                                <Td>{data?.reduced_need_for_maintenance.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Reduced Maintenance Cost</Td>
                                <Td>{data?.reduced_maintenance_cost.toString() || 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#9fbbb2"}}>Increased Equipment Lifetime</Td>
                                <Td>{data?.increased_equipment_lifetime.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Enhanced Asset Value</Td>
                                <Td>{data?.enhanced_asset_value.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Improved Lighting Conditions</Td>
                                <Td>{data?.improved_lighting_conditions.toString() || 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#9fbbb2"}}>Improved Air Quality</Td>
                                <Td>{data?.improved_air_quality.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Reduction of Noise</Td>
                                <Td>{data?.reduction_of_noise.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}} >Increased Worker Safety</Td>
                                <Td>{data?.increased_worker_safety.toString() || 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#9fbbb2"}}>Improved Company Reputation</Td>
                                <Td>{data?.improved_company_reputation.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Improved Compliance with Target Agreements Laws and quality systems</Td>
                                <Td>{data?.improved_compliance_with_target_agreements_laws_and_quality_systems.toString() || 'N/A'}</Td>

                                <Td style={{backgroundColor:"#9fbbb2"}}>Increased Sales</Td>
                                <Td>{data?.increased_sales.toString() || 'N/A'}</Td>
                            </Tr>
                            <Tr>
                                <Td style={{backgroundColor:"#9fbbb2"}}>Increased Employee Morale</Td>
                                <Td>{data?.increased_employee_morale.toString() || 'N/A'}</Td>
                            </Tr>
                            {isAuth() &&<Tr>
                                <Td style={{backgroundColor:"rgba(51,54,100,0.41)"}}>Created by:</Td>
                                <Td>{data?.created_by || 'N/A'}</Td>
                                <Td style={{backgroundColor:"rgba(51,54,100,0.41)"}}>Created at:</Td>
                                <Td>{data?.created_at || 'N/A'}</Td>
                                <Td style={{backgroundColor:"rgba(51,54,100,0.41)"}}>Edited at:</Td>
                                <Td>{data?.edited_by || 'N/A'}</Td>
                            </Tr>}
                        </Tbody>
                    </Table>
            </div>
            </SimpleGrid>

        </>
      );


}

