import React from 'react';
import {Box, Button, Center, Flex, Icon, Image, LightMode, SimpleGrid} from "@chakra-ui/react";
function ESMDataTemplate() {
  // Replace 'your-preselected-file.xlsx' with the path to your Excel file.
  const preselectedFile = '/path/to/your-preselected-file.xlsx';
  const googleDocsLink = 'https://docs.google.com/spreadsheets/d/1Md_sJfSKQpbYBH_ACsnqU5AexD7NaDK9/edit?usp=sharing&ouid=109111308534843193429&rtpof=true&sd=true';


  return (
    <div>
      <p>ESM Data Template File</p>
      <Button
        as="a"
        href={googleDocsLink}
        target="_blank"
        rel="noopener noreferrer"
        colorScheme="teal"
      >Download File</Button>
    </div>
  );
}

export default ESMDataTemplate;
