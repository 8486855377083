import React from 'react';
import XLSX from 'xlsx';
import Card from "components/card/Card";
import {Button} from "@chakra-ui/react"
import GetPayload from "./PayloadTemplate";
import {getUserInfoLocalStorage} from "../../../../components/auth";

const ExcelImporter = ({setValidDataFlag,setDataToUpload,modalHook,setAlertMessage}) => {
    const [file, setFile] = React.useState(null);
    function CheckData(data) {
        // Check if data has valid entries in the required columns (A to H, Mn N, O, Q, AL, AO, AW)
        // const requiredColumns = ["A", "B", "C", "D", "E", "F", "G", "H", "M", "N", "O", "Q", "AL", "AO", "AW"];
        //
        // for (const row of data) {
        //   for (const column of requiredColumns) {
        //     if (row[column] === null || row[column] === "") {
        //       return false;
        //     }
        //   }
        // }
        return true; // Return true if data is valid, false otherwise
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        // Check if file type is valid
        if (file.type !== "application/vnd.ms-excel" && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            alert("Invalid file type. Only Excel files with the extensions .xls and .xlsx are allowed.");
            return;
        }

        reader.onload = (event) => {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, {type: 'binary'});
            const columnLetters = [
                "A", "B", "C", "D", "E", "F", "G", "H", "I", "J",
                "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T",
                "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD",
                "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM",
                "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV",
                "AW"
            ];
            const requiredColumns = [0, 1, 2, 3, 5, 6, 7, 12, 13, 14, 16, 37, 40, 48]; // 4 Nace code in not mandatory for now

            // const worksheetName = workbook.SheetNames[0];
            const worksheetName = "A2M_DataTemplate";
            const worksheet = workbook.Sheets[worksheetName];
            const range = "A10:BM5000"; // Specify range to parse

            let parsedData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: null, // Set default value for empty cells to null
                range, // Pass range option
            });
            // Filter out rows where all cells are null or empty
             parsedData=parsedData.filter(row => row.some(cell => cell !== null && cell !== ""));

            // Filter out rows
            let uncomplited_ROWS_list = [];
            let uncomplited_COLUMNS_list = [];
            const nonEmptyRows = parsedData.filter((row,index) => {
                for (const colIndex of requiredColumns) {  //this loop check the mandatory fields
                    if (row[colIndex] === null || row[colIndex] === "") {
                       uncomplited_COLUMNS_list.push(columnLetters[colIndex])
                    }
                }

                if (uncomplited_COLUMNS_list.length>0) {
                    uncomplited_ROWS_list.push({"rowNumber":index,"missingFields":uncomplited_COLUMNS_list})
                    uncomplited_COLUMNS_list =[];
                    return false;
                }

                if (typeof row[15] !== 'number') {// if the row[15] "ESM's Lifetime (Years)" is not a number then set it to null for the backend
                    console.log("Caught some issues in excel");
                    row[15] = null;
                    return true;
                }
                return true; // If all required columns are non-empty, keep the row
            });

            if (uncomplited_ROWS_list.length > 0) {
                let alertMessage = "You are trying to import: "+"<b>"+ parsedData.length+"</b>"+" Esms !"+"<br>"+
                    "There are: "+"<b>"+uncomplited_ROWS_list.length+"</b>"+ " Esms that are missing mandatory fields:"+"<br>"+
                    "If you want to continue, press "+"<b>"+"Close and then Upload ESM File"+"</b>"+"<br>"

                uncomplited_ROWS_list.forEach(function (entry) {
                    alertMessage += "Esm Entry: " + entry.rowNumber + " --> Missing Mandatory Field at: " + entry.missingFields + "<br>";
                });
                setAlertMessage(alertMessage);

                // alert(alertMessage);
            }

            // Check if data is valid
            if (!CheckData(nonEmptyRows)) {
                setValidDataFlag(false);
                alert("Invalid data in file.");
                return;
            } else {
                setValidDataFlag(true);
            }
            const listOfEsms=[];
            for (const row in nonEmptyRows) {

                listOfEsms.push(GetPayload(nonEmptyRows[row], getUserInfoLocalStorage()?.email))
            }

            setDataToUpload(listOfEsms);
            setFile(file);
        };

        reader.readAsBinaryString(file);
    };

    const handleFileClear = () => {
        setDataToUpload(null);
        setFile(null);
    };

    return (
        <Card>
            <div>
                <input type="file"
                       accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                       onChange={handleFileUpload}/>
                {file && <Button onClick={handleFileClear}>Remove file</Button>}
            </div>
        </Card>
    );
};

export default ExcelImporter;
