import React, {useState, useEffect} from 'react';
import FileUploadPage from "./uploadExecutiveSheet";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Input,
    FormLabel,
    FormControl,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td, Center
} from '@chakra-ui/react';
import axios from "axios";
import {getToken} from "../../../../components/auth";

var hostName = process.env.REACT_APP_HOSTNAME.toString();
export const EditEsmModal = ({isOpen, onClose, selectedEsmToEdit,setSelectedEsmToEdit}) => {
    const [selectedEsm, setSelectedEsm] = useState(selectedEsmToEdit);
    const [dropdownLists,setDropdownLists]= useState({});
    const [companySizeOptions, setCompanySizeOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [industryOptions, setIndustryOptions] = useState([]);
    const [prodSiteDataOptions, setProdSiteDataOptions] = useState([]);
    const [statusOfImplementationOptions, setStatusOfImplementationOptions] = useState([]);
    const [technologyGroupOptions, setTechnologyGroupOptions] = useState([]);
    const [esmDataFetched, setEsmDataFetched] = useState(false);
    const [companySize, setCompanySize] = useState("");
    const [country, setCountry] = useState("");
    const [industry, setIndustry] = useState("");
    const [prodSiteData, setProdSiteData] = useState("");
    const [statusOfImplementation, setStatusOfImplementation] = useState("");
    const [technologyGroup, setTechnologyGroup] = useState("");
    function editEsm(){

        const esmUpdatedAttributes = {};

        const tableRows = document.getElementsByTagName('tr');
        for (let i = 0; i < tableRows.length; i++) {
            const row = tableRows[i];

            // Get the input elements within the current row
            const inputs = row.getElementsByTagName('input');
            for (let j = 0; j < inputs.length; j++) {

                const input = inputs[j];
                const value = input.value;
                const key = input.getAttribute('data-key');
                const placeholder = input.getAttribute('placeholder');
                if (placeholder !== value && value){ //check if the value exists
                    // console.log("this keys has changed:",key)
                    // console.log("Previous Value:",placeholder)
                    // console.log("New value:",value)
                    esmUpdatedAttributes[key]=value
                }

            }
            if (companySize!=""){
                esmUpdatedAttributes["companySize"]=companySize
            }
            if (country!=""){
                esmUpdatedAttributes["country"]={"name": country}
            }
            if (industry!=""){
                esmUpdatedAttributes["industry"]={"title": industry}
            }
            if (prodSiteData!=""){
                esmUpdatedAttributes["prodSiteData"]= prodSiteData
            }
            if (statusOfImplementation!=""){
                esmUpdatedAttributes["statusOfImplementation"]= statusOfImplementation
            }
            if (technologyGroup!=""){
                esmUpdatedAttributes["technologyGroup"]= {"title": technologyGroup}
            }


        }
        console.log(esmUpdatedAttributes)

        return esmUpdatedAttributes;
    }
    function updateEsm(id, editedEsm) {
        const authToken = getToken();
        const Headers = {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json', // You may need to set the content type based on your API requirements
            }
        };
        if (Object.keys(editedEsm).length === 0){
            alert("No change to update");
        }
        else {
            axios.put(hostName + '/update_esm/' + id, editedEsm,Headers)
                .then((response) => {
                    console.log(response.data.esmCollection);
                    alert("Esm updated successfully");
                    onClose();
                    window.location.reload();
                    ///TODO update esm datatable or reload page to get new values
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        console.error(err); // Handle any errors that occur during the request
                        alert(`Your session has timed out. You have to log in again to perform this action! You will be redirected to the login page.`);
                        window.location.href = '/#/auth/sign-in/default';
                    }
                    else {
                        console.log(err);
                        alert(err.message);
                    }
                });
        }
    };
    function getDropDownLists() {
        axios.get(hostName + '/items_list' )
            .then((response) => {
                console.log(response.data.data);
                setDropdownLists(response.data.data)

            })
            .catch((err) => {
                console.log(err);
                alert("Error at get_dropdown_lists");
            });
    };
    function getESMData (selectedESMID)  {
            const esm_id = selectedESMID;
            const url = `${hostName}/get_esm_by_id/`;
            axios.get(url, {params: {esm_id}})
                .then((response) => {
                    const responseData = response.data.esmCollection[0];
                    setSelectedEsm(responseData);

                });



    };

    useEffect(() => {
        getDropDownLists();
        if (Object.keys(selectedEsmToEdit).length !== 0 ){
            const selectedESMID= selectedEsmToEdit._id.$oid;
            getESMData(selectedESMID);
        }
    }, [selectedEsmToEdit]);

    useEffect(() => {
    setCompanySizeOptions(dropdownLists.CompanySize);
  }, [dropdownLists.CompanySize]);

    useEffect(() => {
    setCountryOptions(dropdownLists.Country);
  }, [dropdownLists.Country]);

    useEffect(() => {
    setIndustryOptions(dropdownLists.Industry);
  }, [dropdownLists.Industry]);

    useEffect(() => {
    setProdSiteDataOptions(dropdownLists.ProdSiteData);
  }, [dropdownLists.ProdSiteData]);

    useEffect(() => {
    setStatusOfImplementationOptions(dropdownLists.StatusOfImplementation);
  }, [dropdownLists.StatusOfImplementation]);

    useEffect(() => {
    setTechnologyGroupOptions(dropdownLists.TechnologyGroup);
  }, [dropdownLists.TechnologyGroup]);

    return (
        <>

            <Modal className="EditEsm-modal" size={"full"}
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay/>
                <ModalContent className="custom-modalContent" >
                    <ModalHeader>Edit ESM</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody >
                        <TableContainer>
                            <Table variant='striped' colorScheme='teal' size='xl'>
                                {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
                                <Thead>
                                    <Tr>
                                        <Th>ESM DATA TYPE</Th>
                                        <Th>ESM DATA</Th>
                                        <Th>ESM DATA TYPE</Th>
                                        <Th>ESM DATA</Th>
                                        <Th>ESM DATA TYPE</Th>
                                        <Th>ESM DATA</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>A2M Partner</Td>
                                        <Td><Input  data-key="a2mPartner" placeholder={selectedEsm?.a2mPartner ? selectedEsm?.a2mPartner : "None"}/></Td>
                                        <Td>Title</Td>
                                        <Td><Input data-key="title" placeholder={selectedEsm?.title  ? selectedEsm?.title : "None"}/></Td>
                                        <Td>Production Site Data</Td>
                                         <Td>
                                            <select  onChange={(e) => setProdSiteData(e.target.value)} >
                                                <option id="prodSiteData" data-key="prodSiteData" value="">{selectedEsm?.prodSiteData ?? "None"}</option>
                                                {prodSiteDataOptions && prodSiteDataOptions.map((optionValue, index) => (
                                                    <option key={index} value={optionValue}>{optionValue}</option>
                                                ))}
                                            </select>
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td>Reference Year</Td>
                                        <Td><Input data-key="referenceYear" placeholder={selectedEsm?.referenceYear ? selectedEsm?.referenceYear : "None"}/></Td>
                                        <Td>NACE Code</Td>
                                        <Td><Input data-key="naceCode" placeholder={selectedEsm?.naceCode ? selectedEsm?.naceCode : "None"}/></Td>
                                        <Td>Company Size</Td>
                                        <Td>
                                            <select  onChange={(e) => setCompanySize(e.target.value)}>
                                                <option id="companySizeDropdown" data-key="companySize" value="">{selectedEsm?.companySize ? selectedEsm?.companySize : "None"}</option>
                                                {companySizeOptions && companySizeOptions.map((optionValue, index) => (
                                                    <option key={index} value={optionValue}>{optionValue}</option>
                                                ))}
                                            </select>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Industry</Td>
                                        <Td>
                                            {/*The below code is commented in order to prevent the change of the industry that would need score recalculation*/}
                                            {/*<select  onChange={(e) => setIndustry(e.target.value)}>*/}
                                            {/*    <option id="industryDropdown" data-key="industry" value="">{selectedEsm?.industry?.title ? selectedEsm?.industry?.title : "None"}</option>*/}
                                            {/*    {industryOptions && industryOptions.map((optionValue, index) => (*/}
                                            {/*        <option key={index} value={optionValue}>{optionValue}</option>*/}
                                            {/*    ))}*/}
                                            {/*</select>*/}
                                            <Td>{selectedEsm?.industry?.title ? selectedEsm?.industry?.title : "None"}</Td>
                                        </Td>
                                        <Td>Country</Td>
                                        <Td>
                                            <select  onChange={(e) => setCountry(e.target.value)}>
                                                <option id="countryDropdown" data-key="country" value="">{selectedEsm?.country?.name ? selectedEsm?.country?.name : "None"}</option>
                                                {countryOptions && countryOptions.map((optionValue, index) => (
                                                    <option key={index} value={optionValue}>{optionValue}</option>
                                                ))}
                                            </select>
                                        </Td>
                                        <Td>Description</Td>
                                        <Td><Input data-key="description" placeholder={selectedEsm?.description ? selectedEsm?.description : "None"}/></Td>
                                    </Tr>
                                    <Tr>

                                        <Td>Status Of Implementation</Td>
                                         <Td>
                                            <select  onChange={(e) => setStatusOfImplementation(e.target.value)}>
                                                <option id="statusOfImplementation" data-key="statusOfImplementation" value="">{selectedEsm?.statusOfImplementation ? selectedEsm?.statusOfImplementation  : "None"}</option>
                                                {statusOfImplementationOptions && statusOfImplementationOptions.map((optionValue, index) => (
                                                    <option key={index} value={optionValue}>{optionValue}</option>
                                                ))}
                                            </select>
                                        </Td>
                                        <Td>Technology Group</Td>
                                         <Td >
                                            <select style={{ maxWidth: '140px' }} id="technologyGroup" data-key="technologyGroup" onChange={(e) => setTechnologyGroup(e.target.value)}>
                                                <option value="">{selectedEsm?.technologyGroup?.title ?? "None"}</option>
                                                {technologyGroupOptions && technologyGroupOptions.map((optionValue, index) => (
                                                    <option key={index} value={optionValue}>{optionValue}</option>
                                                ))}
                                            </select>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Company's Turnover (k€)</Td>
                                        <Td><Input data-key="companyTurnover" placeholder={selectedEsm?.companyTurnover ?? "None"}/></Td>
                                        <Td>Company's Primary Energy Consumption (TOE)</Td>
                                        <Td><Input data-key="companyEnConsumption" placeholder={selectedEsm?.companyEnConsumption ?? "None"}/></Td>
                                        <Td>Company's Production Output (Tonnes of product)</Td>
                                        <Td><Input  data-key="companyProdOutput" placeholder={selectedEsm?.companyProdOutput ?? "None"}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Comments or Links</Td>
                                        <Td><Input data-key="commentsLinks" placeholder={selectedEsm?.commentsLinks ?? "None"}/></Td>
                                        <Td>Capital Expenditure (k€)</Td>
                                        <Td><Input data-key="capitalExpenditure" placeholder={selectedEsm?.capitalExpenditure ?? "None"}/></Td>
                                        <Td>Lifetime</Td>
                                        <Td><Input data-key="lifetime" placeholder={selectedEsm?.lifetime ?? "None"}/></Td>
                                    </Tr>
                                    {/*<Tr>*/}
                                    {/*    <Td>Energy Carrier 1</Td>*/}
                                    {/*    <Td><Input data-key="energyCarrier1" placeholder={selectedEsm?.energyCarrier1 ?? "None"}/></Td>*/}
                                    {/*    <Td>Energy Carrier 2</Td>*/}
                                    {/*    <Td><Input data-key="energyCarrier2" placeholder={selectedEsm?.energyCarrier2 ?? "None"}/></Td>*/}
                                    {/*    <Td>Energy Carrier 3</Td>*/}
                                    {/*    <Td><Input data-key="energyCarrier3" placeholder={selectedEsm?.energyCarrier3 ?? "None"}/></Td>*/}
                                    {/*</Tr>*/}
                                    <Tr>
                                        {/*<Td>Energy Carrier 4</Td>*/}
                                        {/*<Td><Input data-key="energyCarrier4" placeholder={selectedEsm?.energyCarrier4 ?? "None"}/></Td>*/}
                                        {/*<Td>Energy Carrier 5</Td>*/}
                                        {/*<Td><Input data-key="energyCarrier5" placeholder={selectedEsm?.energyCarrier5 ?? "None"}/></Td>*/}
                                        <Td>Energy Carrier Primary Consumption Before 1 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsBefore1"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsBefore1 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsBefore1.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Energy Carrier Primary Consumption Before 2 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsBefore2"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsBefore2 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsBefore2.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Energy Carrier Primary Consumption Before 3 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsBefore3"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsBefore3 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsBefore3.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Energy Carrier Primary Consumption Before 4 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsBefore4"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsBefore4 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsBefore4.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Energy Carrier Primary Consumption Before 5 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsBefore5"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsBefore5 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsBefore5.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Energy Carrier Primary Consumption After 1 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsAfter1"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsAfter1 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsAfter1.toFixed(4)
                                    : 'None'
                                }/></Td>
                                        <Td>Energy Carrier Primary Consumption After 2 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsAfter2"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsAfter2 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsAfter2.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Energy Carrier Primary Consumption After 3 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsAfter3"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsAfter3 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsAfter3.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Energy Carrier Primary Consumption After 4 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsAfter4"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsAfter4 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsAfter4.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Energy Carrier Primary Consumption After 5 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimConsAfter5"
                                            placeholder={typeof selectedEsm?.energyCarrierPrimConsAfter5 === 'number'
                                    ? selectedEsm?.energyCarrierPrimConsAfter5.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Energy Carrier Primary Energy Saving 1 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimEnergySaving1" placeholder={typeof selectedEsm?.energyCarrierPrimEnergySaving1 === 'number'
                                    ? selectedEsm?.energyCarrierPrimEnergySaving1.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Energy Carrier Primary Energy Saving 2 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimEnergySaving2" placeholder={typeof selectedEsm?.energyCarrierPrimEnergySaving2 === 'number'
                                    ? selectedEsm?.energyCarrierPrimEnergySaving2.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Energy Carrier Primary Energy Saving 3 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimEnergySaving3" placeholder={typeof selectedEsm?.energyCarrierPrimEnergySaving3 === 'number'
                                    ? selectedEsm?.energyCarrierPrimEnergySaving3.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Energy Carrier Primary Energy Saving 4 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimEnergySaving4" placeholder={typeof selectedEsm?.energyCarrierPrimEnergySaving4 === 'number'
                                    ? selectedEsm?.energyCarrierPrimEnergySaving4.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Energy Carrier Primary Energy Saving 5 (TOE)</Td>
                                        <Td><Input data-key="energyCarrierPrimEnergySaving5" placeholder={typeof selectedEsm?.energyCarrierPrimEnergySaving5 === 'number'
                                    ? selectedEsm?.energyCarrierPrimEnergySaving5.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Total Primary Energy Saved (TOE)</Td>
                                        <Td><Input data-key="totalPrimaryEnergySaved" placeholder={typeof selectedEsm?.totalPrimaryEnergySaved === 'number'
                                    ? selectedEsm?.totalPrimaryEnergySaved.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Renewable Energy Use Before (TOE)</Td>
                                        <Td><Input data-key="renewableEnergyUseBefore" placeholder={typeof selectedEsm?.renewableEnergyUseBefore === 'number'
                                    ? selectedEsm?.renewableEnergyUseBefore.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Renewable Energy Use After (TOE)</Td>
                                        <Td><Input data-key="renewableEnergyUseAfter" placeholder={typeof selectedEsm?.renewableEnergyUseAfter === 'number'
                                    ? selectedEsm?.renewableEnergyUseAfter.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Simple Payback Period (Years)</Td>
                                        <Td><Input data-key="simplePaybackPeriod" placeholder={typeof selectedEsm?.simplePaybackPeriod === 'number'
                                    ? selectedEsm?.simplePaybackPeriod.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Total Avoided Energy Cost (k€)</Td>
                                        <Td><Input data-key="total_avoided_energy_cost_eur" placeholder={typeof selectedEsm?.total_avoided_energy_cost_eur === 'number'
                                    ? selectedEsm?.total_avoided_energy_cost_eur.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Net Present Value (k€) </Td>
                                        <Td><Input data-key="net_present_value" placeholder={typeof selectedEsm?.net_present_value === 'number'
                                    ? selectedEsm?.net_present_value.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Discount Rate Used (%)</Td>
                                        <Td><Input data-key="discount_rate_used" placeholder={typeof selectedEsm?.discount_rate_used === 'number'
                                    ? selectedEsm?.discount_rate_used.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Benefit Cost Ratio</Td>
                                        <Td><Input data-key="benefit_cost_ratio" placeholder={typeof selectedEsm?.benefit_cost_ratio === 'number'
                                    ? selectedEsm?.benefit_cost_ratio.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Internal Rate of Return (%)</Td>
                                        <Td><Input data-key="internal_rate_of_return" placeholder={typeof selectedEsm?.internal_rate_of_return === 'number'
                                    ? selectedEsm?.internal_rate_of_return.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Percentage of Capex Subsidy (%)</Td>
                                        <Td><Input data-key="percentage_capex_subsidy" placeholder={typeof selectedEsm?.percentage_capex_subsidy === 'number'
                                    ? selectedEsm?.percentage_capex_subsidy.toFixed(4)
                                    : 'None'}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Waste Reduction (%)</Td>
                                        <Td><Input data-key="waste_reduction" placeholder={typeof selectedEsm?.waste_reduction === 'number'
                                    ? selectedEsm?.waste_reduction.toFixed(4)
                                    : 'None'}/></Td>
                                        <Td>Carbon Savings (tCO2/Year)</Td>
                                        <Td><Input data-key="carbon_savings" placeholder={typeof selectedEsm?.carbon_savings === 'number'
                                    ? selectedEsm?.carbon_savings.toFixed(4)
                                    : 'None'}/></Td>

                                        <Td>Improved Product Quality</Td>
                                        <Td><Input data-key="improved_product_quality" placeholder={selectedEsm?.improved_product_quality ?? "None"}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Raw Material use Reduction</Td>
                                        <Td><Input data-key="raw_material_use_reduction"
                                            placeholder={selectedEsm?.raw_material_use_reduction ?? "None"}/></Td>

                                        <Td>Improved Production Efficiency</Td>
                                        <Td><Input data-key="improved_production_efficiency" placeholder={selectedEsm?.improved_production_efficiency ?? "None"}/></Td>

                                        <Td>Improved Equipment Performance</Td>
                                        <Td><Input data-key="improved_equipment_performance" placeholder={selectedEsm?.improved_equipment_performance ?? "None"}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Reduced Need for Maintenance</Td>
                                        <Td><Input data-key="reduced_need_for_maintenance"
                                            placeholder={selectedEsm?.reduced_need_for_maintenance ?? "None"}/></Td>

                                        <Td>Reduced Maintenance Cost</Td>
                                        <Td><Input data-key="reduced_maintenance_cost" placeholder={selectedEsm?.reduced_maintenance_cost ?? "None"}/></Td>

                                        <Td>Increased Equipment Lifetime</Td>
                                        <Td><Input data-key="increased_equipment_lifetime"
                                            placeholder={selectedEsm?.increased_equipment_lifetime ?? "None"}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Enhanced Asset Value</Td>
                                        <Td><Input data-key="enhanced_asset_value" placeholder={selectedEsm?.enhanced_asset_value ?? "None"}/></Td>

                                        <Td>Improved Lighting Conditions</Td>
                                        <Td><Input data-key="improved_lighting_conditions"
                                            placeholder={selectedEsm?.improved_lighting_conditions ?? "None"}/></Td>

                                        <Td>Improved Air Quality</Td>
                                        <Td><Input data-key="improved_air_quality" placeholder={selectedEsm?.improved_air_quality ?? "None"}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Reduction of noise</Td>
                                        <Td><Input data-key="reduction_of_noise" placeholder={selectedEsm?.reduction_of_noise ?? "None"}/></Td>

                                        <Td>Increased worker Safety</Td>
                                        <Td><Input data-key="increased_worker_safety" placeholder={selectedEsm?.increased_worker_safety ?? "None"}/></Td>

                                        <Td>Improved Company Reputation</Td>
                                        <Td><Input data-key="improved_company_reputation"
                                            placeholder={selectedEsm?.improved_company_reputation ?? "None"}/></Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Improved Compliance with target <br/>agreements laws and quality systems</Td>
                                        <Td><Input data-key="improved_compliance_with_target_agreements_laws_and_quality_systems"
                                            placeholder={selectedEsm?.improved_compliance_with_target_agreements_laws_and_quality_systems ?? "None"}/></Td>

                                        <Td>Increased sales</Td>
                                        <Td><Input data-key="increased_sales" placeholder={selectedEsm?.increased_sales ?? "None"}/></Td>

                                        <Td>Increased Employee Morale</Td>
                                        <Td><Input data-key="increased_employee_morale" placeholder={selectedEsm?.increased_employee_morale ?? "None"}/></Td>
                                    </Tr>


                                </Tbody>

                            </Table>
                        </TableContainer>
                        <FormControl>
                        </FormControl>
                    </ModalBody>
                     <Center><FileUploadPage esm_id={selectedEsm?._id}></FileUploadPage ></Center>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => {
                            console.log(selectedEsm)
                            updateEsm(selectedEsm._id.$oid, editEsm())
                            // uploadPDF(selectedEsm._id.$oid)
                        }}>
                            Update
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

