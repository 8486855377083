import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import React, {useState, useCallback, useMemo,} from 'react';
import {Button, useDisclosure} from "@chakra-ui/react";
import {useEffect} from "react";
import styled from 'styled-components';
import {typeOf} from "react-is";
import {isAuth, isAdmin, getUserInfoLocalStorage, getToken, saveTokenLocalStorage} from "../../../../components/auth";
import {string} from "react-table/src/sortTypes";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import {EditEsmModal} from "./editEsmModal";
import {EsmDetails} from "./esmDetails";
import axios from "axios";
var hostName = process.env.REACT_APP_HOSTNAME.toString()
const customSort = (rows, selector, direction) => {
  return rows.sort((a, b) => {
    const aField = parseFloat(selector(a));
    const bField = parseFloat(selector(b));

    if (isNaN(aField) && isNaN(bField)) {
      return 0; // Treat both NaN values as equal
    }

    if (isNaN(aField)) {
      return direction === 'desc' ? 1 : -1; // NaN values go to the end when ascending, beginning when descending
    }

    if (isNaN(bField)) {
      return direction === 'desc' ? -1 : 1; // NaN values go to the end when ascending, beginning when descending
    }

    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return direction === 'desc' ? comparison * -1 : comparison;
  });
};
const openPdfInNewTab = (executive_sheet_id) => {
  // Replace the URL with the actual endpoint for fetching the PDF based on esmId
  const pdfUrl = hostName+`/get_pdf/${executive_sheet_id}`;
  // console.log(esmId.$oid)
  const newWindow = window.open(pdfUrl, '_blank');

  if (!newWindow) {
    alert('Failed to open a new tab. Please check your browser settings.');
  }
};
const visitorColumns = [
    {
        name: 'Title',
        selector: row => row.title ? row.title : "none",
        sortable: true,
        compact:true,
        reorder:true,
        width:"90px",
    },
    {
        name: 'ID',
        selector: row => row.ESM_Custom_Id,
        sortable: true,
        compact:true,
        reorder:true
    },
    {
        name: 'Industry',
        selector: row => row.industry.title ? row.industry.title : "none",
        sortable: true,
        compact:true,
        reorder:true,
        width:"90px",
    },
    {
        name: 'Technology Group',
        selector: row => row.technologyGroup.title,
        sortable: true,
        compact:true,
        reorder:true
    },
    {
        name: 'Description',
        selector: row => row.description,
        sortable: true,
        compact:true,
        width:"90px",
        reorder:true
    },
    {
        name: 'Lifetime',
        selector: row => row.lifetime ? row.lifetime.toFixed(0): "N/A" ,
        sortable: true,
        compact:true,
        width:"90px",
        reorder:true,
        center:true

    },
    {
        name: 'Status of Implementation',
        selector: row => row.statusOfImplementation,
        sortable: true,
        compact:true,
        reorder:true
    },
    {
        name: 'Energy Score %',
        selector: row => row.energy_aggregate ? (row.energy_aggregate).toFixed(2)  : "N/A",
        sortable: true,
        width:"90px",
        compact:true,
    },
    {
        name: 'Environmental Score %',
        selector: row => row.environmental_aggregate ? (row.environmental_aggregate).toFixed(2) : "N/A",
        sortable: true,
        width:"90px",
        compact:true,
    },
    {
        name: 'Financial Score %',
        selector: row => row.financial_aggregate ? (row.financial_aggregate).toFixed(2) : "N/A",
        sortable: true,
        width:"90px",
        compact:true,
    },
    {
        name: 'NEB Score %',
        selector: row => row.NEB_aggregate ? (row.NEB_aggregate).toFixed(2) : "N/A",
        sortable: true,
        width:"90px",
        align: "left",
        compact:true,

    },
     {
        name: 'Total Score %',
        selector: row => row.totalEsmScore ? (row.totalEsmScore).toFixed(2) : "N/A",
        sortable: true,
        width:"90px",
        align: "left",
        compact:true,


    },
    {
            name: 'Executive Sheet File',
            key: "Executive Sheet File",
            text: "Executive Sheet File",
            className: "Executive_Sheet_File",
            compact: true,
            width: "90px",
            align: "left",
            sortable: true,
            cell: (record) => {
                return (
                    <div>
                        {record["executive_sheet_id"]  && <Button colorScheme="teal" bg={'#89a8b4'}// Change the color here
                                _hover={{color: 'white'}} color={'white'}
                                className="btn btn-primary btn-sm"
                                onClick={() => openPdfInNewTab(record['executive_sheet_id'])}
                        >View
                        </Button>}
                    </div>
                );
            },
        }


];

// const columnNameToRemove = 'Industry';
// const adminColumns = columns.filter(column => column.name !== columnNameToRemove); //remone column

interface Row {
    title: string;
    director: string;
    year: string;
}
interface Props extends ExpanderComponentProps<Row> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
}

const ExpandableRowComponent: React.FC<Props> = ({ data, someTitleProp }) => {
  const [esm_info, setEsmInfo] = useState(null); // Initialize esm_info as null

  useEffect(() => {
      console.log(data)
    const fetchData = async () => {

      const esm_id = data._id.$oid;
      const url = `${hostName}/get_esm_by_id/`;

      try {
        const response = await axios.get(url, { params: { esm_id }});
        console.log(response)
        const responseData = response.data.esmCollection[0];
        setEsmInfo(responseData);
        console.log(responseData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [data._id]); // Add data._id as a dependency for the effect

  return (
    <div>
      {esm_info ? (
        <EsmDetails data={esm_info} someTitleProp={someTitleProp} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

function EsmDataTable({onOpen,setSelectedEsmToEdit,data,setfinalData,finalData,currentUserCompany}) {
    const authToken = getToken();

    const deleteEsm = async (ids) => {
        // Define the URL
        const url = `${hostName}/delete_esm`;
        // Define the headers
        const headers = {
            'accept': 'application/json',
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        };
        // Define the data to be sent in the request body

        // const data = ids.split(',');
        const data = ids.map(id=>id);

        await axios.delete(url, {headers, data})
            .then(response => {
                console.log(response); // Handle the response data here
                alert(response.data.message)
                window.location.reload();
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    console.error(error); // Handle any errors that occur during the request
                    alert(`Your session has timed out. You have to log in again to perform this action!
                    You will be redirected to the login page.`);
                    window.location.href = '/#/auth/sign-in/default';
                }
            });
        }
        const handleDeleteFromCheckbox = () => {

			if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
				setToggleCleared(!toggleCleared);
                console.log(selectedRows.map(r => r._id.$oid))
				deleteEsm(selectedRows.map(r => r._id.$oid))
                // window.location.reload();
			}
		};
        const handleDelete = (selectedRows) => {

			if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.title}?`)) {
				setToggleCleared(!toggleCleared);

				deleteEsm([selectedRows._id.$oid])
                // window.location.reload();
			}
		};
        const handleEdit = (selectedRows) => {

			if (window.confirm(`Are you sure you want to edit:\r ${selectedRows.title}?`)) {
				setToggleCleared(!toggleCleared);
				console.log(selectedRows)
                setSelectedEsmToEdit(selectedRows)
                onOpen()

			}
		};

    const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);


    const adminColumn = [
        {
            name: 'Edit Esm',
            key: "edit",
            text: "edit",
            className: "edit",
            compact: true,
            width: "90px",
            align: "left",
            sortable: true,
            cell: (record) => {
                return (
                    <div>
                        <Button bgGradient={'linear-gradient(90deg, #000000 0%, #5f5f5f 100%)'}
                                _hover={{color: 'white'}} color={'white'}
                                className="btn btn-primary btn-sm"
                                onClick={() => handleEdit(record)}
                        >Edit
                        </Button>
                    </div>
                );
            },
        }
    ]


    const simpleUserColumn = [
        {
            name: 'Edit Esm',
            key: "edit",
            text: "edit",
            className: "edit",
            compact: true,
            width: "90px",
            align: "left",
            sortable: true,
            cell: (record) => {

                if (record.a2mPartner.toLowerCase() === currentUserCompany.toLowerCase()) {
                    return (
                        <div>
                            <Button bgGradient={'linear-gradient(90deg, #000000 0%, #5f5f5f 100%)'}
                                    _hover={{color: 'white'}} color={'white'}
                                    className="btn btn-primary btn-sm"
                                    onClick={() => handleEdit(record)}
                            >Edit
                            </Button>
                        </div>
                    );
                }
            },
        },
        {
            name: 'Delete Esm',
            key: "Delete",
            text: "Delete",
            className: "Delete",
            width: "90px",
            center: true,
            compact: true,
            align: "left",
            sortable: true,
            cell: (record) => {

               if (record.a2mPartner.toLowerCase() === currentUserCompany.toLowerCase()) {
                   return (
                       <div>
                           <Button bgGradient={'linear-gradient(90deg, #8B0000 0%, #FFC0CB 100%)'}
                                   _hover={{color: 'white'}} color={'white'}
                                   className="btn btn-primary btn-sm"
                                   onClick={() => handleDelete(record)}
                           >Delete
                           </Button>
                       </div>
                   );
               }
            },
        }
    ]
	const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const contextActions = React.useMemo(() => {
		return (
            <div>
			<Button key="delete" onClick={handleDeleteFromCheckbox} style={{ backgroundColor: 'red' }} icon>
				Delete
			</Button>
                </div>
		);
	}, [selectedRows, toggleCleared]);


    var isAdminVar = localStorage.getItem('is_superuser')==="true" ? true : false;
    const [columns,setColumns]=useState([])
    var text;
    const inputRef = React.createRef();
    const TextField = styled.input`
      height: 32px;
      width: 200px;
      border-radius: 3px;
      border: 1px solid #e5e5e5;
      padding: 0 16px; /* Adjust the padding as needed */

      &:hover {
        cursor: pointer;
      }
    `;
    const ClearButton = styled(Button)`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 34px;
      width: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    `;
    const handleFilterTextChange = (e) => {
        console.log(e.target.value)
        text=e.target.value
    };

    const FilterComponent = ({filterText, onClear, onGo}) => (
        <><ClearButton type="button" onClick={onClear}>X</ClearButton>
            <TextField
                id="search1"
                placeholder="Filter Description"
                aria-label="Search Input"
                value={filterText}
                ref={inputRef}
                onChange={handleFilterTextChange}
            />

            <ClearButton type="button" onClick={onGo}>GO</ClearButton>
        </>
    );

    const handleGo = () => {
    if (text) {
        const filteredData = data.filter((item) =>
            item.description && item.description.toLowerCase().includes(text.toLowerCase())
        );
        setfinalData(filteredData);
    } else {
        console.warn("initData or text is undefined");
    }
}

    const handleClear = () =>  {
        if (inputRef.current) { inputRef.current.value = ""; }
        setfinalData(data)
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <FilterComponent onClear={handleClear} onGo={handleGo}/>
        );
    }, [finalData]);

    async function SetColumns() {

        if (await isAdmin()) {
            const adminColumns = visitorColumns.concat(adminColumn);
            setColumns(adminColumns)
        } else if (isAuth()) {
            const simpleUserColumns = visitorColumns.concat(simpleUserColumn);
            setColumns(simpleUserColumns)
        } else {
            setColumns(visitorColumns)
        }
    }
    useEffect(() => {
        SetColumns()
    }, [currentUserCompany]);


    useEffect(() => {

    }, [finalData]);


    return (
        <div>

        <DataTable className="dataTable_class"
            sortFunction={customSort}
            title="ESM LIST"
                 // isAdmin() ? adminColumns : isAuth() ? simpleUserColumns : visitorColumns
            //isAdminVar ? adminColumns : (isAuth() ? simpleUserColumns : visitorColumns)
            columns={columns} //if user is admin adminColumns if user is logged in and not admin
            data={finalData}
             selectableRows={isAdminVar} //and if user is admin
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            pagination
            // paginationResetDefaultPage={resetPaginationToggle}
            // progressPending={pending}
            defaultSortFieldId={1}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            expandableRows
            expandableRowsComponent={ExpandableRowComponent}
                   fixedHeader={true}
                   fixedHeaderScrollHeight={300}
        /></div>
    );
};

export default EsmDataTable;