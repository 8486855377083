import {Alert, AlertDescription, AlertIcon, AlertTitle, ListItem, UnorderedList, useBoolean} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { createContext } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

var hostName = process.env.REACT_APP_HOSTNAME.toString()
export async function saveTokenLocalStorage(token) {
    await localStorage.setItem('accessToken', token);
}

export function isAuth() {
    // console.log("Check token :" +typeof localStorage.getItem('accessToken'))
    return localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== 'null'
        && localStorage.getItem('accessToken') !== null;
}

export async function isAdmin() {
    try {
        var userObj = JSON.parse(localStorage.getItem('userInfo'))
        // console.log("Check is_superuser :" + userObj.is_superuser)
        if (userObj.is_superuser === true) {
            localStorage.setItem('is_superuser', true);
            // console.log("Check is_superuser : true" )
            return true;
        } else {
            localStorage.setItem('is_superuser', false);
            // console.log("Check is_superuser : false" )
            return false;
        }
    } catch (error) {
        localStorage.setItem('is_superuser', false);
        return false;
    }
    // return JSON.parse(localStorage.getItem('userInfo'))
}

export function getToken() {
    return localStorage.getItem('accessToken');
}

export async function saveUserInfoLocalStorage(userInfo) {
    await localStorage.setItem('userInfo', JSON.stringify(userInfo));
    // console.log("setLS:-->",getUserInfoLocalStorage()?.email);
}

export function getUserInfoLocalStorage() {
    // console.log("getLS:-->",localStorage.getItem('userInfo'))
    return JSON.parse(localStorage.getItem('userInfo'));
}

export function LogOut(){
    var token = getToken();
    // console.log(token)
    axios.post(hostName+'/auth/jwt/logout',
            null,
            {
                // withCredentials: true,
                headers: {

                    'Authorization': `Bearer ${token}`,
                },
            }
        )
            .then((response) =>{
                // console.log(response)
            })

            .catch((error) => console.log(error));
    saveTokenLocalStorage(null)
    saveUserInfoLocalStorage(null)
    window.location.reload()
}
export function PrivateRoute({ children, ...rest }) {
    let auth = isAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                rest.availableToAll || (auth != rest.isPublic) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: !rest.isPublic ? "/auth/sign-in" : "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export function catchError(error) {

    if (error.response.status == 401) {
        window.location.href = '/u/logout';
    }

    if (error.response.status == 403) {
        window.location.href = '/';
    }

}

export function SetupAxios() {
    let token = getToken();

    if (isAuth() && token) {
        setAxiosErrorInterceptor();
        setAxiosAUthorizationHeader(token);
    }

    return null;

}

export function setAxiosAUthorizationHeader(token) {

    axios.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });
}

export function setAxiosErrorInterceptor() {
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response.status == 401) {
            window.location.href = '/u/logout';
        }

        if (error.response.status == 403) {
            window.location.href = '/u/logout';
        }

        return Promise.reject(error);
    }
    );
}

export const MeContext = React.createContext();



