const GetPayLoad=(RowData,esmOwner)=> {
        const payloadTemplate =  {
                    "a2mPartner": RowData[0],
                    "title": RowData[1],
                    "prodSiteData": RowData[2],
                    "referenceYear": RowData[3],
                    "naceCode": RowData[4],
                    "industry": {"title": RowData[5]},
                    "country": {"name": RowData[6],},
                    "companySize": RowData[7],
                    "companyTurnover": RowData[8],
                    "companyEnConsumption": RowData[9],
                    "companyProdOutput": RowData[10],
                    "commentsLinks": RowData[11],
                    "description": RowData[12],
                    "statusOfImplementation": RowData[13],
                    "capitalExpenditure": RowData[14],
                    "lifetime": typeof RowData[15] === 'number' ? RowData[15] : null,
                    "technologyGroup": {"title": RowData[16],},
                    "energyCarrier1": RowData[17],
                    "energyCarrier2": RowData[18],
                    "energyCarrier3": RowData[19],
                    "energyCarrier4": RowData[20],
                    "energyCarrier5": RowData[21],
                    "energyCarrierPrimConsBefore1": RowData[22],
                    "energyCarrierPrimConsBefore2": RowData[23],
                    "energyCarrierPrimConsBefore3": RowData[24],
                    "energyCarrierPrimConsBefore4": RowData[25],
                    "energyCarrierPrimConsBefore5": RowData[26],
                    "energyCarrierPrimConsAfter1":RowData[27],
                    "energyCarrierPrimConsAfter2": RowData[28],
                    "energyCarrierPrimConsAfter3": RowData[29],
                    "energyCarrierPrimConsAfter4": RowData[30],
                    "energyCarrierPrimConsAfter5": RowData[31],
                    "energyCarrierPrimEnergySaving1": RowData[32],
                    "energyCarrierPrimEnergySaving2": RowData[33],
                    "energyCarrierPrimEnergySaving3": RowData[34],
                    "energyCarrierPrimEnergySaving4": typeof RowData[35] === 'number' ? RowData[35] : null,
                    "energyCarrierPrimEnergySaving5": typeof RowData[36] === 'number' ? RowData[36] : null,
                    "totalPrimaryEnergySaved": typeof RowData[37] === 'number' ? RowData[37] : null,
                    "renewableEnergyUseBefore": typeof RowData[38] === 'number' ? RowData[38] : null,
                    "renewableEnergyUseAfter": typeof RowData[39] === 'number' ? RowData[39] : null,
                    "simplePaybackPeriod": typeof RowData[40] === 'number' ? RowData[40] : null,
                    "total_avoided_energy_cost_eur": typeof RowData[41] === 'number' ? RowData[41] : null,
                    "net_present_value": typeof RowData[42] === 'number' ? RowData[42] : null,
                    "discount_rate_used": typeof RowData[43] === 'number' ? RowData[43] : null,
                    "benefit_cost_ratio": typeof RowData[44] === 'number' ? RowData[44] : null, //these values are not duplicated they exist 2 times in the excel file
                    "internal_rate_of_return": typeof RowData[45] === 'number' ? RowData[45] : null,
                    "percentage_capex_subsidy": RowData[46],
                    "waste_reduction": RowData[47],
                    "carbon_savings":RowData[48], //these values are not duplicated they exist 2 times in the excel file
                    "improved_product_quality":typeof RowData[49] === 'string' ? "Yes" : "No",
                    "raw_material_use_reduction": typeof RowData[50] === 'string' ? "Yes" : "No",
                    "improved_production_efficiency": typeof RowData[51] === 'string' ? "Yes" : "No",
                    "improved_equipment_performance":typeof RowData[52] === 'string' ? "Yes" : "No",
                    "reduced_need_for_maintenance": typeof RowData[53] === 'string' ? "Yes" : "No",
                    "reduced_maintenance_cost": typeof RowData[54] === 'string' ? "Yes" : "No",
                    "increased_equipment_lifetime": typeof RowData[55] === 'string' ? "Yes" : "No",
                    "enhanced_asset_value": typeof RowData[56] === 'string' ? "Yes" : "No",
                    "improved_lighting_conditions": typeof RowData[57] === 'string' ? "Yes" : "No",
                    "improved_air_quality": typeof RowData[58] === 'string' ? "Yes" : "No",
                    "reduction_of_noise": typeof RowData[59] === 'string' ? "Yes" : "No",
                    "increased_worker_safety": typeof RowData[60] === 'string' ? "Yes" : "No",
                    "improved_company_reputation": typeof RowData[61] === 'string' ? "Yes" : "No",
                    "improved_compliance_with_target_agreements_laws_and_quality_systems": typeof RowData[62] === 'string' ? "Yes" : "No",
                    "increased_sales": typeof RowData[63] === 'string' ? "Yes" : "No",
                    "increased_employee_morale": typeof RowData[64] === 'string' ? "Yes" : "No",
                    "created_at":    typeof RowData[64] === 'string' ? RowData[64] : null,
                    "created_by":    typeof RowData[65] === 'string' ? RowData[65] : null,
                    "edited_at":    typeof RowData[66] === 'string' ? RowData[66] : null,
                    "edited_by":    typeof RowData[67] === 'string' ? RowData[67] : null,
                    // "PES_KPI1": RowData[65],
                    // "energy_intensity_reduction_KPI2": RowData[66],
                    // "consumption_reduction_per_product_KPI3": RowData[67],
                    // "cost_of_energy_savings_KPI4": RowData[68],
                    //
                    // "carbon_savings_KPI5": RowData[48],
                    // "cost_of_carbon_savings_KPI6": RowData[70],
                    // "carbon_reduction_per_product_KPI7": RowData[71],
                    // "renewable_energy_use_gain_KPI8": RowData[72],
                    // "simple_payback_period_KPI9": RowData[73],
                    // "net_present_value_KPI10": RowData[42],
                    // "benefit_cost_ratio_KPI11": RowData[44],
                    // "internal_rate_of_return_KPI12": RowData[45],
                    // "productivity_KPI13": RowData[77],
                    // "Q_M_KPI14": RowData[78],
                    // "work_environment_KPI15": RowData[79],
                    // "other_NEBs_KPI16": RowData[80],
                    "esmOwner": esmOwner

            }
            return payloadTemplate;
}
export default GetPayLoad;
