
import React from 'react';
import axios from "axios";
import {getToken} from "../../../../../src/components/auth";
// import {getToken} from "../../../../../../frontend/src/components/auth";
var hostName = process.env.REACT_APP_HOSTNAME.toString();

function PostEsm(data,closeModal ) {
    console.log(data);
    const authToken = getToken();
    const Headers = {
        headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json', // You may need to set the content type based on your API requirements
        }
    };
    axios.post(hostName + '/post_esm', data, Headers)
        .then((response) => {
            if (response.status===201 || response.status===200) {
                closeModal();
                alert(`You have successfully uploaded ${response.data.entries_stored} entrie/s`);

            }
            closeModal();
        })
        .catch((err) => {
            closeModal();
            if (err.response.status===401) {
                alert(`Your session has timed out. You have to log in again!
                You will be redirected to the login page.`);
                window.location.href = '/#/auth/sign-in/default';
            }
            if (err.response && err.response.status === 422) {
                const details = err.response.data.detail;
                let alertMessage = "Validation Error:\n";

                for (const detail of details) {
                    // console.log(detail)
                    const row = detail.loc[1]-2;
                    const column = detail.loc[2];
                    const msg = detail.msg;
                    alertMessage += `On Row: ${row} and Column: ${column}--> Issue: ${msg}\n`;
                }
                alert(alertMessage);
                // alert(err.response.data.detail[0].loc[2] +":"+ err.response.data.detail[0].msg + "at ESM entry number"+ err.response.data.detail[0].loc[1] );
            }
            if (err.response && err.response.status === 400) {
                const message = err.response.data.message;
                let alertMessage = message;
                alert(alertMessage);

            }
            if (err.response && err.response.status === 500) {
                const message = err.response.data.message;
                let alertMessage = message;
                alert(alertMessage);

            }
            console.log(err);
        });
}
export default PostEsm;