/*eslint-disable*/
import React from "react";
import life_logo from "../../../src/assets/life_logo.jpg";
import {
  Image,
  Box,
  Container,
  Center,
  Flex,
  Link,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  const { toggleColorMode } = useColorMode();
  return (
    <Flex
      bg="white"
      alignItems="center"
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      justifyContent="space-between"
      px={{ base: "10px", md: "30px" }}
      py="20px"
    >
      <Center w="100px" padding="2">
        <Image src={life_logo} />
      </Center>
      <Center>
        <Text fontSize="xs">
          AUDIT2MEASURE is co-funded by the European Union under GA
          101075785.
          <br />
          Views and opinions expressed are however those of the author(s) only
          and do not necessarily reflect those of the European Union or CINEA.
          <br />
          Neither the European Union nor the granting authority can be held
          responsible for them.
        </Text>
      </Center>

      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="200" ms="4px">
          EPU NTUA<br/>
          {/*<Link*/}
          {/*  mx="3px"*/}
          {/*  color={textColor}*/}
          {/*  href="https://www.google.com"*/}
          {/*  target="_blank"*/}
          {/*  fontWeight="300"*/}
          {/*>*/}
            Developed for AUDIT2MEASURE Project
          {/*</Link>*/}
        </Text>
      </Text>
      <List display="flex" alignItems="center">
        {/*<ListItem me={{ base: "20px", md: "44px" }}>*/}
          {/*<Link*/}
          {/*  fontWeight="300"*/}
          {/*  color={textColor}*/}
          {/*  href="https://www.simmmple.com/licenses"*/}
          {/*>*/}
          {/*  License*/}
          {/*</Link>*/}
        {/*</ListItem>*/}
        {/*<ListItem me={{ base: "20px", md: "44px" }}>*/}
        {/*  <Link*/}
        {/*    fontWeight="300"*/}
        {/*    color={textColor}*/}
        {/*    href="https://simmmple.com/terms-of-service"*/}
        {/*  >*/}
        {/*    Terms of Use*/}
        {/*  </Link>*/}
        {/*</ListItem>*/}
        <ListItem me={{ base: "20px", md: "44px" }}>
          <Link
            fontWeight="300"
            color={textColor}
            href="mailto:dddimitrakopoulos@epu.ntua.gr"
          >
            Support
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
