// Chakra imports
import {Box, Button, Text,Center, Flex, Icon, Image, LightMode, SimpleGrid} from "@chakra-ui/react";
import {FaBeer, FaDatabase, FaHome} from 'react-icons/fa';
// Custom components
import Card from "components/card/Card";
import React from "react";
import Details from "views/admin/esmImport/components/Details";
import Dropzone from "views/admin/esmImport/components/DropzoneCard";
import Info from "views/admin/esmImport/components/Info";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import ExcelImporter from "./components/XlsxImport";
import {Select} from '@chakra-ui/react';
import PostEsm from "./components/postEsm";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
// import { Select } from 'antd';
import CustomModal from "../../../components/modal/Modal";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
} from '@chakra-ui/react';
import ESMDataTemplate from "./components/ESMDataTemplate";
import ExportDataToXlsx from "../dataTables/components/exportDataToxlsx";
import {isAuth} from "../../../components/auth";
// const { Option } = Select;
var hostName = process.env.REACT_APP_HOSTNAME.toString()
export default function EsmImport() {
    const [validDataFlag, setValidDataFlag] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState('');
    const [dataToUpload,setDataToUpload] = React.useState([]);
    const [alertMessage,setAlertMessage] = React.useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenCustom,
        onOpen: onOpenCustom,
        onClose: onCloseCustom,
    } = useDisclosure();

    useEffect(() => {
        // setDataToUpload()
    }, [selectedOption]);

    function UploadBtnPressed() {
        console.log("UploadBtnPressed");
        onOpen()
        PostEsm(dataToUpload,onClose)

    }
    useEffect(() => {
        if(alertMessage!== ""){
       onOpenCustom()}
    }, [alertMessage]);

    return (
        <center><>
            <CustomModal isOpen={isOpenCustom} onClose={onCloseCustom} data={alertMessage}></CustomModal>
            {/* link Button to home & Database */}
            <Box pt={{base: "8px", md: "8px", xl: "8px"}} width={"50%"}>
                <SimpleGrid
                    columns={{sm: 1, xl: 2}}
                    spacing={{base: "20px", xl: "20px"}}>
                    <Link to="/admin/main">
                        <Card>
                            <Center>
                                <Icon as={FaHome} boxSize={12}/>
                            </Center>
                        </Card>
                    </Link>
                    <Link to="/admin/esm">
                        <Card>
                            <Center>
                                <Icon as={FaDatabase} boxSize={12}/>
                            </Center>
                        </Card>
                    </Link>
                </SimpleGrid>
            </Box>

            {/*Importing Options */}
            <Box pt={{base: "8px", md: "8px", xl: "8px"}} width={"32%"}>
                <Select placeholder='Select option' value={selectedOption}
                onChange={(e) => {setSelectedOption(e.target.value)}}>
                    {isAuth() && <option value='xlsxImportEsm'>Import Esm/s from xlsx file</option>}
                    <option value='exportData'>Export Esm/s to xlsx file</option>
                    {/*<option value='Form'>Form Upload</option>*/}
                </Select>
            </Box>

            {/*Import XLSX*/}
            {selectedOption==="xlsxImportEsm" && isAuth() && <Box pt={{base: "8px", md: "8px", xl: "8px"}} width={"32%"}>
                <div style={{ margin: '20px' }}></div>
                <Text fontSize='xl'>*Please note that the database accept only a specific data template. We suggest you to download, fill and upload the ESM Data Template File!</Text>
                <div style={{ margin: '20px' }}></div>
                <Card><ESMDataTemplate></ESMDataTemplate></Card>
                {/* an empty div to create space */}
                <div style={{ margin: '20px' }}></div>
                <Text fontSize='xl'>Below you can select and upload the filled ESM Data Template File.</Text>
                <div style={{ margin: '20px' }}></div>
                <ExcelImporter setValidDataFlag={setValidDataFlag} setDataToUpload={setDataToUpload}  setAlertMessage={setAlertMessage}/>
            </Box>}

            {selectedOption==="exportData" && <Box pt={{base: "8px", md: "8px", xl: "8px"}} width={"32%"}>
                <div style={{ margin: '20px' }}></div>
                <Card><ExportDataToXlsx/></Card>
                {/* an empty div to create space */}
                <div style={{ margin: '20px' }}></div>

            </Box>}
            {/*FORM*/}
            { selectedOption==="Form" && <Box pt={{base: "8px", md: "8px", xl: "8px"}} width={"60%"}>
                <SimpleGrid
                    columns={{sm: 1, xl: 2}}
                    spacing={{base: "20px", xl: "20px"}}>
                    {/* Column Left */}
                    <Flex direction='column'>
                        {/*<Card mb='20px'>*/}
                        {/*  <Image*/}
                        {/*    borderRadius='20px'*/}
                        {/*    h={{ base: "auto", xl: "396px", "2xl": "auto" }}*/}
                        {/*    // src={ChairDef}*/}
                        {/*  />*/}
                        {/*</Card>*/}

                        <Info/>
                    </Flex>
                    {/* Column Right */}
                    <Flex direction='column'>
                        <Dropzone mb='20px'/>
                    </Flex>
                </SimpleGrid>
                <Details/>
            </Box>}

            {/*Upload button*/}
            <Box pt={{base: "8px", md: "8px", xl: "8px"}} width={"60%"}>
                {validDataFlag && <Card
                    p='60px 30px'
                    // flexDirection={{base: "column", md: "row"}}
                    alignItems='center'>
                    <Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' onClick={UploadBtnPressed}>
                        Upload ESM File
                    </Button>
                </Card>}
            </Box>
           <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Uploading ESMs. Please wait!</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Center><CircularProgress isIndeterminate color='green.300' /></Center>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
            </>
        </center>
    );
}
