import React, {useEffect, useState} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from "axios";

var hostName = process.env.REACT_APP_HOSTNAME.toString()
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: "PES min/max/avg (TOE) values",
    },
  },
  scales: {
    x: {
      stacked: true, // Enable stacking on the x-axis
    },
    y: {
      stacked: true, // Enable stacking on the y-axis
    },
  },
};


 function ScorePerIndustry() {
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
        get_PES_per_industry() //call at initialization
    }, []);
  function get_PES_per_industry() {
    axios.get(hostName+'/get_pes_score_per_industry')
        .then((response)=>{generateGraph(response.data.data)} )
    }
  function generateGraph(response) {
      console.log(response)
    const labels = response.map((item) => item._id);
    const countList = response.map((item) => item.count);
    const minValues = response.map((item) => item.minPES_KPI1); // Replace with actual data
    const maxValues = response.map((item) => item.maxPES_KPI1); // Replace with actual data
    const avgValues = response.map((item) => item.averagePES_KPI1); // Replace with actual data
    setChartData({
          labels,
          datasets: [
              {
                  label: 'Min Value',
                  data: minValues,
                  borderColor: '#94a464',
                  backgroundColor: '#94a464',

              },
              {
                  label: 'Avg Value',
                  data: avgValues,
                  borderColor: '#9fbbb2',
                  backgroundColor: '#9fbbb2',
              },
              {
                  label: 'Max Value',
                  data: maxValues,
                  borderColor: '#bfc9b6',
                  backgroundColor: '#bfc9b6',
              },

          ],
    });
  }


  return ( <div>
    {chartData ? <Bar data={chartData} options={options}  /> : null}
  </div>)
}


export default ScorePerIndustry;
