import {
    Box, FormControl, FormLabel, Input, Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,Flex,
    SimpleGrid, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Icon
} from "@chakra-ui/react";
import EsmDataTable from "./components/dataTable";
import { Container } from '@chakra-ui/react';
import React, {useState, useEffect} from 'react';
import Footer from "../../../components/footer/FooterAdmin";
import Card from "../../../components/card/Card.js";
import Sidebar from "../../../components/sidebar/Sidebar";
import axios from "axios";
import {getUserInfoLocalStorage, isAuth, LogOut} from "../../../components/auth";
import {SpinnerCircularSplit} from "spinners-react";
import { Portal, useDisclosure ,Button} from "@chakra-ui/react";

import { Grid, GridItem } from '@chakra-ui/react'
import {RiLogoutBoxLine} from "react-icons/ri";
import {EditEsmModal} from "./components/editEsmModal";
var hostName = process.env.REACT_APP_HOSTNAME.toString()
export default function ESMPage() {
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const { isOpen, onOpen, onClose} = useDisclosure()
    const [selectedEsmToEdit, setSelectedEsmToEdit] = useState({})

    const [selectedCollectionItems, setSelectedCollectionItems] = useState({Energy:[0,100],
                                                                                    Environmental:[0,100],
                                                                                    NEBs:[0,100],
                                                                                    Financial:[0,100],
                                                                                    Total:[0,100]});
    const [filteredCollectionsData, setFilteredCollectionsData] = useState()
    const [finalData, setfinalData] = useState()
    const [itemsSum, setItemsSum] = useState(0)
    const [searchBarText, setSearchBarText] = useState("")
    const [EnergyScoreRange, setEnergyScoreRange] = React.useState([0,100]);
    const [EnvironmentalScoreRange, setEnvironmentalScoreRange] = React.useState([0,100]);
    const [NEBsScoreRange, setNEBsScoreRange] = React.useState([0,100]);
    const [FinancialScoreRange, setFinancialScoreRange] = React.useState([0,100]);
    const [TotalScoreRange,setTotalScoreRange] = React.useState([0,100]);
    const [pending, setPending] = React.useState(true);
    const [localStorageReady, setLocalStorageReady] = useState(false);
    const [currentUserCompany,setCurrentUserCompany] = React.useState(getUserInfoLocalStorage()?.company);
    useEffect(() => {
        getFilteredCollectionsData(selectedCollectionItems) //call at initialization
    }, []);
    useEffect(() => {
    }, [onOpen]);

    useEffect(() => {
    const checkLocalStorage = () => {
      if (typeof window.localStorage !== 'undefined') {
        setLocalStorageReady(true);
      }
    };

    const interval = setInterval(checkLocalStorage, 500); // Check every second

    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  }, []);

  useEffect(() => {
    if (localStorageReady) {
        setCurrentUserCompany(getUserInfoLocalStorage()?.company)
    }
  }, [localStorageReady]);

    function convertObject(obj) {
        obj = removeEmptyArrays(obj);
        const result = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (key==="countries"){
                    key="country";
                    result[`${key}.name`] = {"$in": value};
                }
            else if (key==="company_size"){
                    key="companySize";
                    result[`${key}`] = {"$in": value};
                }
            else if (key==="executive_sheet"){
                    key="executive_sheet_id";
                    result[`${key}`] = {"$exists": true,$ne: null};
                }
            else if (key==="Energy" || key==="NEBs" || key==="Financial" || key==="Environmental" || key=="Total"){//{score : { $gt :  0, $lt : 600}}
                // result[`energy_aggregate`] = {"$gte": EnergyScoreRange[0], "$lte": EnergyScoreRange[1]};

                result[`$and`] =
                        [
                            {
                                 "$or": [
                            {"energy_aggregate": {
                                "$gte": EnergyScoreRange[0],
                                 "$lte": EnergyScoreRange[1]}},
                            {"energy_aggregate": {"$eq": null}}
                                 ]
                            },
                            {
                                "$or": [
                                    {
                                        "environmental_aggregate": {
                                            "$gte": EnvironmentalScoreRange[0],
                                            "$lte": EnvironmentalScoreRange[1]
                                        }
                                    },
                                    {"environmental_aggregate": {"$eq": null}}]
                            },
                            {
                                "$or": [
                                    {"financial_aggregate": {"$gte": FinancialScoreRange[0], "$lte": FinancialScoreRange[1]}},
                                    {"financial_aggregate": {"$eq": null}},

                                ]

                            },
                            {
                                "$or": [
                                     {"NEB_aggregate": {"$gte": NEBsScoreRange[0], "$lte": NEBsScoreRange[1]}},
                                     {"NEB_aggregate": {"$eq": null}}
                                ]
                            },
                             {
                                "$or": [
                                     {"totalEsmScore": {"$gte": TotalScoreRange[0], "$lte": TotalScoreRange[1]}},
                                     {"totalEsmScore": {"$eq": null}}
                                ]
                            }


                        ];

            }
            // else if (key==="Environmental"){//{score : { $gt :  0, $lt : 600}}
            //     result[`environmental_aggregate`] = {"$gte": EnvironmentalScoreRange[0],"$lte":EnvironmentalScoreRange[1]};
            // }
            // else if (key==="Financial"){//{score : { $gt :  0, $lt : 600}}
            //     result[`financial_aggregate`] = {"$gte": FinancialScoreRange[0],"$lte":FinancialScoreRange[1]};
            // }
            // else if (key==="NEBs"){//{score : { $gt :  0, $lt : 600}}
            //     result[`NEB_aggregate`] = {"$gte": NEBsScoreRange[0],"$lte":NEBsScoreRange[1]};
            // }
            else{
                result[`${key}.title`] = {"$in": value};
            }
        });

        return result;
    }

    function removeEmptyArrays(inputObj) {
        const outputObj = {};
        let hasNonEmptyArray = false;
        for (const key in inputObj) {
            if (Array.isArray(inputObj[key]) && inputObj[key].length > 0) {
                outputObj[key] = inputObj[key];
                hasNonEmptyArray = true;
            }
        }
        return hasNonEmptyArray ? outputObj : {};
    }

    function getFilteredCollectionsData(data) {
        if (data !=={}) {

            data = convertObject(data);
        }
        setPending(true)
        // console.log("Test 1",data)
        axios.post(hostName + '/filter_data/esmCollection'
            , data)
            .then((response) => {
                setFilteredCollectionsData(response.data.esmCollection);
                setfinalData(response.data.esmCollection)
                setPending(false)
            })
            .catch((err) => {
                console.log(err);
                setPending(false)
            });
    };

    const handleOnChangeCheckbox = (collection, item) => {
        setSelectedCollectionItems(prevSelectedCollectionItems => {
            let items = prevSelectedCollectionItems[collection] || [];
            const index = items.indexOf(item);
            if (index === -1) {
                items = [...items, item];
            } else {
                items = [...items.slice(0, index), ...items.slice(index + 1)];
            }
            return {...prevSelectedCollectionItems, [collection]: items};
        });
    };
    const handleOnChangeSliderEnergy = (newValue) => {
          // console.log(newValue)
          setEnergyScoreRange(newValue);
          // getFilteredCollectionsData(selectedCollectionItems)
    };
    const handleOnChangeSliderNEBs = (newValue) => {
          // console.log(newValue)
        setNEBsScoreRange(newValue);
          // getFilteredCollectionsData(selectedCollectionItems);
    };
    const handleOnChangeSliderFinancial= (newValue) => {
          // console.log(newValue)
        setFinancialScoreRange(newValue);
          // getFilteredCollectionsData(selectedCollectionItems);
    };
    const handleOnChangeSliderEnvironmental = (newValue) => {
          // console.log(newValue)
        setEnvironmentalScoreRange(newValue);
          // getFilteredCollectionsData(selectedCollectionItems);
    };
     const handleOnChangeSliderTotalScore = (newValue) => {
          // console.log(newValue)
          setTotalScoreRange(newValue);
          // getFilteredCollectionsData(selectedCollectionItems);
    };
    const handleOnSearchButtonPressed = () =>{
        getFilteredCollectionsData(selectedCollectionItems);
    }
    // useEffect(()=>{console.log(getUserInfoLocalStorage())},[])
    // useEffect(() => {
    //     // console.log(selectedCollectionItems)
    //     // filterEsmCollection(selectedCollectionItems)
    //      getFilteredCollectionsData(selectedCollectionItems)
    // }, [selectedCollectionItems]);

     function HandleLogOut() {
        if (isAuth()) {
            LogOut()
            alert("You have succesfully signed out!");
        } else {
            alert("You are already signed out!");
        }
    }
  return (
    // <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     <Grid
  templateAreas={`"header header"
                  "nav main"
                  "nav footer"`}
  gridTemplateRows={'5px 1fr 3px'}
  gridTemplateColumns={'30px 1fr'}
  h='100px'
  gap='1'
  color='blackAlpha.700'
  fontWeight='bold'
>        <GridItem pl='2'  area={'nav'}>
       <Sidebar  display='none' handleOnChangeCheckbox={handleOnChangeCheckbox}
                                handleOnChangeSliderEnergy={handleOnChangeSliderEnergy}
                                handleOnChangeSliderEnvironmental={handleOnChangeSliderEnvironmental}
                                handleOnChangeSliderFinancial={handleOnChangeSliderFinancial}
                                handleOnChangeSliderNEBs={handleOnChangeSliderNEBs}
                                handleOnChangeSliderTotalScore={handleOnChangeSliderTotalScore}
                                EnergyScoreRange={EnergyScoreRange}
                                EnvironmentalScoreRange={EnvironmentalScoreRange}
                                FinancialScoreRange= {FinancialScoreRange}
                                NEBsScoreRange={NEBsScoreRange}
                                TotalScoreRange={TotalScoreRange}
                                handleOnSearchButtonPressed={handleOnSearchButtonPressed}
       />
     </GridItem>
         <GridItem pl='2'  area={'main'}>
          {/*<Portal>*/}
          {/*  <Box>*/}
          {/*    <Navbar*/}
          {/*      // onOpen={onOpen}*/}
          {/*      logoText={"esmDatabase"}*/}
          {/*      brandText={'getActiveRoute(routes)'}*/}
          {/*      brandText={"dimitris"}*/}
          {/*      // secondary={getActiveNavbar(routes)}*/}
          {/*      // message={getActiveNavbarText(routes)}*/}
          {/*      // fixed={fixed}*/}

          {/*    />*/}
          {/*  </Box>*/}
          {/*</Portal>*/}


      <Box
          float='right'
          minHeight='100vh'
          height='100%'
          overflow='auto'
          position='relative'
          maxHeight='100%'
          w={{ base: "100%", xl: "calc( 100% - 220px )" }}
           maxWidth={{ base: "100%", xl: "calc( 100% - 220px )" }}
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
           transitionTimingFunction='linear, linear, ease'>

        <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <Card>
            <div style={{position: "absolute", top: "70%", left: "50%"}}>
                                    {/*{!filteredCollectionsData &&*/}
                                    {/*    <SpinnerCircularSplit size={150} thickness={60}*/}
                                    {/*                                                   secondaryColor={'rgba(215,197,197,0.78)'}*/}
                                    {/*                                                   enabled={true}/>*/}
                                    {/*}*/}
                                </div>
            {
                isAuth() && <Box float='right'>
                    {/*<SimpleGrid*/}
                    {/*    mb='20px'*/}
                    {/*    columns={{sm: 1, md: 1}}*/}
                    {/*    spacing={{base: "20px", xl: "20px"}}>*/}
                        <Card>
  <Flex   alignItems="center">
    <Text>Hello {getUserInfoLocalStorage()?.email} &ensp;</Text>
    <p>
        <Button onClick={HandleLogOut} colorScheme='teal' variant='outline' size="sm">
        <Icon as={RiLogoutBoxLine} w={4} h={4} boxSize={4} />
        </Button>
    </p>
  </Flex>
</Card>
                    {/*</SimpleGrid>*/}
                </Box>
            }
            {pending && (
    <div className="spinner-container" style={{ filter: 'none' }}>
      <SpinnerCircularSplit
        size={150}
        thickness={60}
        secondaryColor={'rgba(215, 197, 197, 0.78)'}
        enabled={true}
        style={{ filter: 'none' }}
      />
    </div>
  )}
            <div className={`data-table-container ${pending ? 'blur-container' : ''}`}>
                <EsmDataTable onOpen={onOpen} setSelectedEsmToEdit={setSelectedEsmToEdit}
                              data={filteredCollectionsData} setfinalData={setfinalData} finalData={finalData} currentUserCompany={currentUserCompany} expandableRows />
    </div>
        </Card>


      </SimpleGrid>
           <Box>
            <Footer />
          </Box>
        </Box>

     <EditEsmModal isOpen={isOpen} onClose={onClose} selectedEsmToEdit={selectedEsmToEdit} setSelectedEsmToEdit={setSelectedEsmToEdit}></EditEsmModal>
         </GridItem>
     </Grid>

  );
}
