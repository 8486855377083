import React, {useEffect, useState} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from "axios";

var hostName = process.env.REACT_APP_HOSTNAME.toString()
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y' ,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right' ,
    },
    title: {
      display: true,
      text: "Number of Esms",
    },
  },
};


export default function CountriesChart() {
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
        getEsmPerCountry() //call at initialization
    }, []);
  function getEsmPerCountry() {
    axios.get(hostName+'/esms_per_country')
        .then((response)=>{generateGraph(response.data.data)} )
    }
    function generateGraph(response){
      const labels = response.map((item)=> item._id)
      const countList = response.map((item)=> item.count)

      setChartData( {
        labels,
        datasets: [
          {
            label: "Sum",
            data: countList,
            borderColor: '#2f6989',
            backgroundColor: '#2f6989',
          },

        ],
      })
    }

  return ( <div>
    {chartData ? <Bar data={chartData} options={options}  /> : null}
  </div>)
}
