// CustomModal.js

import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import React from 'react';

export default function CustomModal({ isOpen, onClose, data, index }) {
  const [scrollBehavior, setScrollBehavior] = React.useState('inside');
  const btnRef = React.useRef(null);

  // Define CSS classes for different modal widths
  const modalWidthClasses = ['w-1/4', 'w-1/2', 'w-3/4', 'w-full']; // Customize these widths as needed

  return (
    <>
      <Modal onClose={onClose} finalFocusRef={btnRef} isOpen={isOpen} scrollBehavior={scrollBehavior} size="xl">
        <ModalOverlay />
        <ModalContent className={modalWidthClasses[index] || modalWidthClasses[0]}> {/* Apply the class based on the index */}
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: data }} />

          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
