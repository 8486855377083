// Chakra imports
import {
    Flex,
    FormControl,
    SimpleGrid,
    Text,
    Select,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import React from "react";

export default function Settings() {
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.600";
    return (
        <FormControl>
            <Card mb={{base: "0px", xl: "20px"}}>
                <Flex direction='column' mb='40px' ms='10px'>
                    <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
                        ESM Information
                    </Text>
                    <Text fontSize='md' color={textColorSecondary}>
                        Here you can put esm information
                    </Text>
                </Flex>
                <SimpleGrid
                    columns={{sm: 1, md: 2}}
                    spacing={{base: "20px", xl: "20px"}}>
                    <InputField
                        mb='0px'
                        me='30px'
                        id='esmTitle'
                        label='ESM title'
                        placeholder='eg. ESM_1'
                    />
                    <Card>
                        <Text fontSize='md' color={textColorPrimary} fontWeight='bold'>
                            Status of Implementation

                        </Text>
                        <Select
                            mb='0px'
                            id='soi'
                            label='Status of Implementation'
                            placeholder='Select option'>
                            <option value='Proposed'>Proposed</option>
                            <option value='Implemented'>Implemented</option>
                        </Select>
                    </Card>
                    <Flex direction='column'>
                        <InputField
                            mb='25px'
                            me='30px'
                            id='capexpenditure'
                            label='Capital Expenditure (kEuro)'
                            placeholder='eg. 100000'
                        />
                        <Card>
                            <Text fontSize='md' color={textColorPrimary} fontWeight='bold'>
                                ESM`s Technology Group</Text>
                            <Select
                                mb='0px'
                                id='esmTechGroup'
                                placeholder='Select option'>
                                <option value='Tech_group_1'>Tech_group_1</option>
                                <option value='Tech_group_2'>Tech_group_2</option>
                                <option value='Tech_group_3'>Tech_group_3</option>
                                <option value='Tech_group_4'>Tech_group_4</option>
                            </Select>
                        </Card>
                    </Flex>
                    <TextField
                        id='description'
                        label='ESM Description'
                        mb='0px'
                        h='100%'
                        placeholder='Short description about the esm'
                    />
                </SimpleGrid>
            </Card>


            <Card mb={{base: "0px", xl: "20px"}}>
                <Flex direction='column' mb='40px' ms='10px'>
                    <Text fontSize='md' color={textColorSecondary}>
                        Company information
                    </Text>
                </Flex>
                <SimpleGrid
                    columns={{sm: 1, md: 2}}
                    spacing={{base: "20px", xl: "20px"}}>
                    <InputField
                        mb='0px'
                        me='30px'
                        id='esmTitle'
                        label='NACE CODE'
                        placeholder='eg. 564'
                    />
                    <Card>
                        <Text fontSize='md' color={textColorPrimary} fontWeight='bold'>
                            Country </Text>
                        <Select
                            mb='0px'
                            id='soi'
                            label='Country'
                            placeholder='Select an option'>
                            <option value='Greece'>Greece</option>
                            <option value='Spain'>Spain</option>
                            <option value='Chech Republic'>Chech Republic</option>
                            <option value='Italy'>Italy</option>
                            <option value='Netherlands'>Netherlands</option>

                        </Select>
                    </Card>

                    <Flex direction='column'>
                        <InputField
                            mb='25px'
                            me='30px'
                            id='companysize'
                            label='Company`s Size'
                            placeholder='eg. 55'
                        />

                    </Flex>
                    <Flex direction='column'>
                        <InputField
                            mb='25px'
                            me='30px'
                            id='companytons'
                            label='Company`s Production Output (tonnes of product) '
                            placeholder='eg. 55'
                        />

                    </Flex>
                </SimpleGrid>
            </Card>
        </FormControl>
    );
}
