import React, { useState } from 'react';
import { Button, Text } from "@chakra-ui/react";
import axios from 'axios';
import XLSX from 'xlsx'; // Import the XLSX library

var hostName = process.env.REACT_APP_HOSTNAME.toString();

function ExportDataToXlsx() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleExportData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Make a GET request to your FastAPI backend endpoint to get JSON data
      const response = await axios.get(hostName + '/get_exported_data_file');

      if (!response.data || response.data.error) {
        throw new Error(response.data ? response.data.error : 'No Data Found');
      }

      // Convert JSON data to XLSX format
      let data= JSON.parse(response.data)
      const jsonData = data.map(item => {
        return {
          ...item,
          country: item.country.name,
          industry: item.industry.title,
          technologyGroup: item.technologyGroup.title
        };
      });
;
      console.log(jsonData)
      const worksheet = XLSX.utils.json_to_sheet(jsonData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate the XLSX file
      XLSX.writeFile(workbook, 'exported_data.xlsx');

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError('Error exporting data.');
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p>Export Database Data to XLSX File</p>
      <Button
        onClick={handleExportData}
        isLoading={isLoading}
        colorScheme="blue"
      >
        Export Data
      </Button>
      {error && <Text color="red">{error}</Text>}
    </div>
  );
}

export default ExportDataToXlsx;
