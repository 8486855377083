import React, { useState } from 'react';
import axios from 'axios';
import {  Button,
} from '@chakra-ui/react';

const FileUploadPage = ({esm_id}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('pdf_file', selectedFile); // Match the field name on the server

        const response = await axios.post(
          `${process.env.REACT_APP_HOSTNAME}/upload_executive_sheet?esm_id=${esm_id.$oid}`, // Pass esm_id as a query parameter
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        setResponseMessage(response.data.response);
      } catch (error) {
        console.error('Error uploading PDF:', error);
        setResponseMessage('Error while uploading the file!');
      }
    } else {
      setResponseMessage('Please select a PDF file before uploading.');
    }
  };

  return (
    <div>
      <input type="file" name="file" onChange={changeHandler} />
      <div>
        <Button colorScheme='blue' mr={4} onClick={handleSubmission}>Submit file</Button>
      </div>
      <div>
        <p>{responseMessage}</p>
      </div>
    </div>
  );
};

export default FileUploadPage;
