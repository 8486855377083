import React, {useContext} from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {  AuthContext ,saveTokenLocalStorage,getToken,saveUserInfoLocalStorage,getUserInfoLocalStorage} from "../../../components/auth"
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Assets
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import {useEffect} from "react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import CenteredAuth from "../../../layouts/auth/types/Centered";
import {isAdmin} from "../../../components/auth";
var hostName = process.env.REACT_APP_HOSTNAME.toString()
function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [password, setpassword] = React.useState("");
  const [username, setusername] = React.useState("");
  const [accessToken, setAccessToken] = React.useState('');

   // const { accessToken,setAccessToken,meInfo,setMeInfo } = useContext(AuthContext);
  useEffect(() => {

    }, [password]);

  function SignIn() {

        var data ="username="+username.toString()+"&password="+password.toString()


        axios.post(hostName + '/auth/jwt/login', data)
            .then((response) => {
                let token = response.data.access_token;
                // console.log(token)
                setAccessToken(token)
                GetMe(token).then(
                    isAdmin().then(
                        saveTokenLocalStorage(token).then(
                            window.location.href = '/#/admin/esm')

                    )
                )

            })
            .catch((err) => {
                console.log(err);
                alert(err.response.data.detail)

            });
    };

  async function GetMe(TOKEN) {

    axios.get(hostName + '/users/me',
    {
      headers: {
        'Authorization':`Bearer ${TOKEN}`, },}).then(async (response) => {
          console.log("Store user data to localstorage")
          await saveUserInfoLocalStorage(response.data)

    })
        .catch((err) => {
          console.log(err);
        });
  };

  return (

    <CenteredAuth
      image={"linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"}
      cardTop={{ base: "140px", md: "14vh" }}
      cardBottom={{ base: "50px", lg: "auto" }}
      mx='0px'>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        justifyContent='center'
        px={{ base: "20px", md: "0px" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          {/*<Button*/}
          {/*  fontSize='sm'*/}
          {/*  me='0px'*/}
          {/*  mb='26px'*/}
          {/*  py='15px'*/}
          {/*  h='50px'*/}
          {/*  borderRadius='16px'*/}
          {/*  bg={googleBg}*/}
          {/*  color={googleText}*/}
          {/*  fontWeight='500'*/}
          {/*  _hover={googleHover}*/}
          {/*  _active={googleActive}*/}
          {/*  _focus={googleActive}>*/}
          {/*  <Icon as={FcGoogle} w='20px' h='20px' me='10px' />*/}
          {/*  Sign in with Google*/}
          {/*</Button>*/}
          {/*<Flex align='center' mb='25px'>*/}
          {/*  <HSeparator />*/}
          {/*  <Text color='gray.400' mx='14px'>*/}
          {/*    or*/}
          {/*  </Text>*/}
          {/*  <HSeparator />*/}
          {/*</Flex>*/}
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isrequired="true"
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              onChange={e => setusername(e.target.value)}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              isrequired="true"
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isrequired="true"
                fontSize='sm'
                ms={{ base: "0px", md: "4px" }}
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                onChange={e => setpassword(e.target.value)}
                type={show ? "text" : "password"}
                variant='auth'
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              {/*<FormControl display='flex' alignItems='center'>*/}
              {/*  <Checkbox*/}
              {/*    id='remember-login'*/}
              {/*    colorScheme='brandScheme'*/}
              {/*    me='10px'*/}
              {/*  />*/}
              {/*  <FormLabel*/}
              {/*    htmlFor='remember-login'*/}
              {/*    mb='0'*/}
              {/*    fontWeight='normal'*/}
              {/*    color={textColor}*/}
              {/*    fontSize='sm'>*/}
              {/*    Keep me logged in*/}
              {/*  </FormLabel>*/}
              {/*</FormControl>*/}
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button onClick={SignIn}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'>
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            {/*<Text color={textColorDetails} fontWeight='400' fontSize='14px'>*/}
            {/*  Not registered yet?*/}
            {/*  <NavLink to='/auth/sign-up'>*/}
            {/*    <Text*/}
            {/*      color={textColorBrand}*/}
            {/*      as='span'*/}
            {/*      ms='5px'*/}
            {/*      fontWeight='500'>*/}
            {/*      Create an Account*/}
            {/*    </Text>*/}
            {/*  </NavLink>*/}
            {/*</Text>*/}
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>

  );
}

export default SignIn;
