import React ,{useState, useEffect} from 'react';
import {Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { RiLogoutBoxLine } from "react-icons/ri";
import Card from "../card/Card";

// chakra imports
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import {Badge ,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react';
import logo from "../../../src/assets/logo.png";
import {Stack,
    Box,
    Flex,
    Drawer,
    DrawerBody,
    Icon,Image ,
    useColorModeValue,
    DrawerOverlay,
    useDisclosure,
    DrawerContent,
    DrawerCloseButton, Center,SimpleGrid
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
// Assets

import axios from "axios";
import {FaDatabase, FaFileImport, FaHome, FaHotel, FaSearch, FaSignInAlt} from 'react-icons/fa';
import {isAuth,LogOut} from "../auth";
import ExportDataToXlsx from "../../views/admin/dataTables/components/exportDataToxlsx";
var hostName = process.env.REACT_APP_HOSTNAME.toString()

function Sidebar({   handleOnChangeSliderTotalScore,
                     TotalScoreRange,
                     handleOnChangeCheckbox,
                     handleOnChangeSliderEnergy,
                     handleOnChangeSliderEnvironmental,
                     handleOnChangeSliderFinancial,
                     handleOnChangeSliderNEBs,
                     EnergyScoreRange,
                     EnvironmentalScoreRange,
                     FinancialScoreRange,
                     NEBsScoreRange,
                     handleOnSearchButtonPressed
                 }) {
  const [industryCol, setIndustryCol] = React.useState([]);
  const [technologyGroupCol, setTechnologyGroupCol] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [companySize, setCompanySize] = React.useState(["Micro","Small","Medium-sized","Large","N/A"]);
  const [executiveSheetSelect, setExecutiveSheetSelect] = React.useState(["Yes"]);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);


  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";
  useEffect(() => {
        getFiltersFieldsList() //call at initialization
    }, []);
    function NavigateToHome(){
            window.location.href = '/#/admin/home';
    }
    function NavigateToImport(){

        // if (isAuth()) {
            window.location.href = '/#/admin/importesm';
        // }
        // else {
        //     onOpen();
        // }
    }
    function HandleSignIn(){

        if (isAuth()) {
            alert("You are already signed in!");
        }
        else {
            window.location.href = '/#/auth/sign-in/default';
        }
    }

    function HandleLogOut() {
        if (isAuth()) {
            LogOut()
            alert("You have succesfully signed out!");
        } else {
            alert("You are already signed out!");
        }
    }
    function getFiltersFieldsList() {
        let collections=["industry","technologyGroup","countries"] //the 3 main filters with checkboxes
        axios.post(hostName + "/get_data",collections)
            .then((response) => {
                         // console.log(response.data.results)
                setIndustryCol(response.data.results[0].industry)
                setTechnologyGroupCol(response.data.results[1].technologyGroup)
                setCountries(response.data.results[2].countries)
            })
            .catch((err) => {
                console.log(err);
            });
    };
  // SIDEBAR
  return (
    <Box display={{ sm: "none", xl: "block" }}width="250px" >
        <Card>
            <center>
                <Box style={{width: "105px", height: "65px"}}>
                    <a href='https://ieecp.org/projects/audit-to-measure/' >
                    <Image src={logo}/>
                        </a>
                </Box>
            </center>
        </Card>
        <SimpleGrid
            columns={{sm: 1, xl: 3}}
            spacing={{base: "1px", xl: "1px"}}>


            {/*<Card>*/}
            <Button onClick={NavigateToHome} bg={'#9fbbb2'}>
                <Center>
                    <Icon as={FaHome} boxSize={5}/>
                </Center>

            </Button>
            {/*</Card>*/}

            {/*<Link to="/admin/importesm">*/}
            {/*    <Card>*/}
            <Button onClick={NavigateToImport} bg={'#89a8b4'}>
                <Center>
                    <Icon as={FaDatabase} boxSize={5}/>
                </Center>
            </Button>
            {/*</Card>*/}
            {/*<Card>*/}
            <Button onClick={HandleSignIn} bg={'#3f7690'}>
                <Center>
                    <Icon as={FaSignInAlt} boxSize={5}/>
                </Center>
            </Button>
            {/*</Card>*/}
            {/*</Link>*/}
        </SimpleGrid>
        {/*<Card>*/}
        {/*  <SearchTextFilter searchBarText={searchBarText}*/}
        {/*                                     setSearchBarText={setSearchBarText}*/}
        {/*                                     setFilteredCollectionsData={setFilteredCollectionsData}></SearchTextFilter>*/}
        {/*</Card>*/}

        <Card style={{overflowY : 'auto',fontSize: '12px'}}>
            <article className="filter-group">
                {/*<header className="card-header">*/}
                {/*        <i className="icon-control fa fa-chevron-down "></i>*/}
                {/*        <h6 className="Title-Color title">Industry</h6>*/}

                {/*</header>*/}
                <Badge>Industry</Badge>
                <div style={{height:'100px',overflowY : 'scroll',fontSize: '12px', position:'relative'}}
                     className="filter-content collapse show" id="collapse_2">
                    <div className="card-body">
                        {industryCol.map((index, row) => {

                            return (
                                <li key={index["title"]} style={{listStyleType: 'none'}}>
                                    <div className="listIndustry-list-item ">

                                        <input style={{"height": '1.2em', "width": "1.2em"}}
                                               type="checkbox"
                                               id={`custom-checkbox-${index["title"]}`}
                                               name={index["title"]}
                                               value={index["title"]}
                                            // checked={checkedState[row]}
                                               onChange={() => handleOnChangeCheckbox("industry",index["title"],row)}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}
                                               style={{marginLeft: '.5rem'}}>{index["title"]}</label>
                                        <b className="badge badge-pill badge-light float-right">{index["count"]}</b>
                                    </div>
                                </li>
                            );

                        })}
                    </div>
                </div>
            </article>
        {/*</Card>*/}
        {/*<Card style={{overflowY : 'auto',fontSize: '12px'}}>*/}
            <Divider/>
            <article className="filter-group">
                {/*<header className="card-header">*/}
                {/*        <i className="icon-control fa fa-chevron-down"></i>*/}
                {/*        <h6 className="Title-Color title">Technology Category</h6>*/}

                {/*</header>*/}

                <Badge>Technology Category</Badge>
                <div style={{height:'90px',overflowY : 'scroll'}} className="filter-content collapse show" id="collapse_3">
                    <div className="card-body">
                        {technologyGroupCol.map((index, row) => {
                            return (
                                <li key={index["title"]} style={{listStyleType: 'none'}}>
                                    <div className="Technology-Category-list-item ">

                                        <input style={{"height": '1.2em', "width": "1.2em"}}
                                               type="checkbox"
                                               id={`custom-checkbox-${index["title"]}`}
                                               name={index["title"]}
                                               value={index["title"]}
                                            // checked={checkedState[row]}
                                                 onChange={() => handleOnChangeCheckbox("technologyGroup",index["title"],row)}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}
                                               style={{marginLeft: '.5rem'}}>{index["title"]}</label>
                                        <b className="badge badge-pill badge-light float-right">{index["count"]}</b>
                                    </div>
                                </li>
                            );

                        })}
                    </div>
                </div>
            </article>
            <Divider/>
            <article className="filter-group">
                {/*<header className="card-header">*/}
                {/*        <i className="icon-control fa fa-chevron-down"></i>*/}
                {/*        <h6 className="Title-Color title">Countries</h6>*/}

                {/*</header>*/}
                <Badge>Countries</Badge>
                <div style={{height:'90px',overflowY : 'scroll',fontSize: '12px', position:'relative'}} className="filter-content collapse show" id="collapse_3">
                    <div className="card-body">
                        {countries.map((index, row) => {
                            return (
                                <li key={index["name"]} style={{listStyleType: 'none'}}>
                                    <div className="listCountries-list-item ">

                                        <input style={{"height": '1.2em', "width": "1.2em"}}
                                               type="checkbox"
                                               id={`custom-checkbox-${index["name"]}`}
                                               name={index["name"]}
                                               value={index["name"]}
                                            // checked={checkedState[row]}
                                             onChange={() => handleOnChangeCheckbox("countries",index["name"],row)}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}
                                               style={{marginLeft: '.5rem'}}>{index["name"]}</label>
                                        <b className="badge badge-pill badge-light float-right">{index["count"]}</b>
                                    </div>
                                </li>
                            );

                        })}
                    </div>
                </div>
            </article>
            <Divider/>
            <article className="filter-group">
                {/*<header className="card-header">*/}
                {/*        <i className="icon-control fa fa-chevron-down"></i>*/}
                {/*        <h6 className="Title-Color title">Countries</h6>*/}

                {/*</header>*/}
                <Badge>Company Size</Badge>
                <div style={{height:'60px',overflowY : 'scroll',fontSize: '12px', position:'relative'}} className="filter-content collapse show" id="collapse_3">
                    <div className="card-body">
                        {companySize.map((index, row) => {

                            return (
                                <li key={index} style={{listStyleType: 'none'}}>
                                    <div className="listSizes-list-item ">

                                        <input style={{"height": '1.2em', "width": "1.2em"}}
                                               type="checkbox"
                                               id={`custom-checkbox-${index}`}
                                               name={index}
                                               value={index}
                                            // checked={checkedState[row]}
                                             onChange={() => handleOnChangeCheckbox("company_size",index,row)}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}
                                               style={{marginLeft: '.5rem'}}>{index}</label>

                                    </div>
                                </li>
                            );

                        })}
                    </div>
                </div>
            </article>
            <Divider/>
            <article className="filter-group">
            {/*    <header className="card-header">*/}
            {/*            <i className="icon-control fa fa-chevron-down"></i>*/}
            {/*            <h6 className="Title-Color title">Countries</h6>*/}

            {/*    </header>*/}
                <Badge>Executive Sheets</Badge>
                <div style={{height:'10px',fontSize: '12px', position:'relative'}} className="filter-content collapse show" id="collapse_4">
                    <div className="card-body">
                        {executiveSheetSelect.map((index, row) => {

                            return (
                                <li key={index} style={{listStyleType: 'none'}}>
                                    <div className="listSizes-list-item ">

                                        <input style={{"height": '1.2em', "width": "1.2em"}}
                                               type="checkbox"
                                               id={`custom-checkbox-${index}`}
                                               name={index}
                                               value={index}
                                            // checked={checkedState[row]}
                                             onChange={() => handleOnChangeCheckbox("executive_sheet",index,row)}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}
                                               style={{marginLeft: '.5rem'}}>{index}</label>

                                    </div>
                                </li>
                            );

                        })}
                    </div>
                </div>
            </article>
        </Card>
        <Card>
            <Box  w='100%'>
            Energy Score
            <Stack direction='row'>
                <Badge colorScheme='green'>{EnergyScoreRange[0]}</Badge>
                <RangeSlider  defaultValue={[0, 100]} min={0} max={100} step={10}
                             onChange={handleOnChangeSliderEnergy}>
                    <RangeSliderTrack >
                        <RangeSliderFilledTrack bg={'#89a8b4'}/>
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0}/>
                    <RangeSliderThumb boxSize={6} index={1}/>
                </RangeSlider>
                <Badge colorScheme='purple' bg={'#94a464'}>{EnergyScoreRange[1]}</Badge>
            </Stack>

            Enviromental Score
            <Stack direction='row'>
                <Badge colorScheme='green'>{EnvironmentalScoreRange[0]}</Badge>
                <RangeSlider defaultValue={[0, 100]} min={0} max={100} step={10}
                             onChange={handleOnChangeSliderEnvironmental}>
                    <RangeSliderTrack bg='red.100'>
                        <RangeSliderFilledTrack bg={'#89a8b4'}/>
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0}/>
                    <RangeSliderThumb boxSize={6} index={1}/>
                </RangeSlider>
                <Badge colorScheme='purple' bg={'#94a464'}>{EnvironmentalScoreRange[1]}</Badge>
            </Stack>

            Financial Score
            <Stack direction='row'>
                <Badge colorScheme='green'>{FinancialScoreRange[0]}</Badge>
                <RangeSlider defaultValue={[0, 100]} min={0} max={100} step={10}
                             onChange={handleOnChangeSliderFinancial}>
                    <RangeSliderTrack bg='red.100'>
                        <RangeSliderFilledTrack bg={'#89a8b4'}/>
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0}/>
                    <RangeSliderThumb boxSize={6} index={1}/>
                </RangeSlider>
                <Badge colorScheme='purple' bg={'#94a464'}>{FinancialScoreRange[1]}</Badge>
            </Stack>
                </Box>

            NEBs Score
            <Stack direction='row'>
                <Badge colorScheme='green'>{NEBsScoreRange[0]}</Badge>
                <RangeSlider defaultValue={[0, 100]} min={0} max={100} step={10}
                             onChange={handleOnChangeSliderNEBs}>
                    <RangeSliderTrack bg='red.100'>
                        <RangeSliderFilledTrack bg={'#89a8b4'}/>
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0}/>
                    <RangeSliderThumb boxSize={6} index={1}/>
                </RangeSlider>
                <Badge colorScheme='purple' bg={'#94a464'}>{NEBsScoreRange[1]}</Badge>
            </Stack>
            Total Score
            <Stack direction='row'>
                <Badge colorScheme='green'>{TotalScoreRange[0]}</Badge>
                <RangeSlider defaultValue={[0, 100]} min={0} max={100} step={10}
                             onChange={handleOnChangeSliderTotalScore}>
                    <RangeSliderTrack bg='red.100'>
                        <RangeSliderFilledTrack bg={'#89a8b4'}/>
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0}/>
                    <RangeSliderThumb boxSize={6} index={1}/>
                </RangeSlider>
                <Badge colorScheme='purple' bg={'#94a464'}>{TotalScoreRange[1]}</Badge>
            </Stack>
        </Card>
        <Card>
        <Center>

        <Button onClick={handleOnSearchButtonPressed}
            colorScheme="green"
                bg={'#2f6989'}
            size="md"
            variant="solid"
            leftIcon={<Icon as={FaSearch}/>}
        >
            Search
        </Button>
            </Center>
            </Card>
        {/*<Card>*/}
        {/*    <Center>*/}
        {/*        <Box style={{width: "75px", height: "35px"}}>*/}
        {/*            <Button onClick={HandleLogOut} colorScheme='teal' variant='outline'>*/}

        {/*                <Icon as={RiLogoutBoxLine} boxSize={5}/>*/}

        {/*            </Button>*/}
        {/*        </Box>*/}
        {/*    </Center>*/}
        {/*</Card>*/}

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Please Note!</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <p>Sorry but you are not logged in.</p>
                    To upload new ESMs you have to log in to you account.
                    Press the Sign In button to be redirected at Sign In page.
                </ModalBody>

                <ModalFooter>
                    {/*<Button   onClick={onClose}>*/}
                    {/*    Close*/}
                    {/*</Button>*/}
                    <Button   mr={3} colorScheme='blue' variant='ghost'>
                        <Link to="/auth/log-in">
                        Sign In
                        </Link>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>

  );
}

Sidebar.propTypes = {
  logoText: PropTypes.string,
  // routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
