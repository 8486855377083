import {Center, Container, Text} from '@chakra-ui/react'
// Chakra imports
import {Image,
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    SimpleGrid,
    useColorModeValue,
} from "@chakra-ui/react";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React , {useEffect} from "react";
import { Link } from 'react-router-dom';
import logo from "../../../assets/logo.png";
import Card from "../../../components/card/Card";
import {
    MdAddTask,MdBusiness,
    MdAttachMoney,MdOutlineStorage,
    MdBarChart,
    MdFileCopy, MdHome,
} from "react-icons/md";

import Footer from "../../../components/footer/FooterAdmin";
import ScorePerIndustry from "./components/AverageScore";
import {Bar} from "react-chartjs-2";
import CountriesChart from "./components/CountriesChart";
import axios from "axios";
var hostName = process.env.REACT_APP_HOSTNAME.toString()
export default function UserReports() {
    useEffect(() => {
    console.log("test")
        getEsmPerCountry() //call at initialization
    }, []);
  function getEsmPerCountry() {
    axios.get(hostName+'/esms_per_country')
        .then((response)=>{console.log(response.data)})
    }
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    return (

        <Box>
            <Box paddingBottom='3'>
            <Center>


                <Image src={logo} boxSize='10%'/>

            </Center>
                </Box>
            <Center>


                <SimpleGrid
                    columns={3}
                    gap='20px'
                    mb='20px'>
                    <Link to="/admin/esm">
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={'#89a8b4'}
                                icon={
                                    <Icon w='32px' h='32px' as={MdOutlineStorage}  />
                                }
                            />
                        }
                        name='ESM Database'
                        // value='$350.4'
                    />
                        </Link>
                    <a href="https://ieecp.org/projects/audit-to-measure/" target="_blank" >
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={'#89a8b4'}
                                icon={
                                    <Icon w='32px' h='32px' as={MdBusiness}  />
                                }
                            />
                        }
                        name='A2M Project Page'
                        // value='$642.39'
                    />
</a>
                                       <a href="https://emmm.a2m.epu.ntua.gr" target="_blank" >
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={'#89a8b4'}
                                icon={
                                    <Icon w='32px' h='32px' as={MdBusiness}  />
                                }
                            />
                        }
                        name='Α2Μ Energy Management Maturity Model'
                        // value='$642.39'
                    />
</a>


                </SimpleGrid>
            </Center>
            <Center>
             <SimpleGrid columns={{base: 1, md: 2, xl: 2}} gap='20px'
                    mb='20px'>
                <Card >
                    <div style={{ whiteSpace: "pre-line" }}>
                     <p style={{ fontSize: '25px' }}>
                         AUDIT-TO-MEASURE
                     </p>
                    {'\n'}
                    <p style={{ fontSize: '16px' }}>Industry is a key player in energy consumption and economic impact in the European Union (EU)
                        and energy audits represent an important tool to improve energy efficiency in the sector;
                        despite both the spread of energy audits and the knowledge of their benefits, the actual implementation
                        rate of the Energy Savings Measures (ESM) proposed by energy audits is relatively low.
                        The main aim of the AUDIT-TO-MEASURE (Leading businesses towards climate neutrality by speeding up
                        the uptake of energy efficiency measures from the energy audits) project is to support companies
                        in the uptake of audits measures necessary to reduce the energy consumption supporting their energy transition.
                        AUDIT-TO-MEASURE will develop and implement a new engagement strategy (called “Audit2Action”)
                        to put into action the opportunities emerging from energy audits.</p>
                    {'\n'}

                </div>

                </Card>
                  <Card >

                    <div style={{ whiteSpace: "pre-line" }}>
                     <p style={{ fontSize: '25px' }}>
                         ESM Database Tool
                     </p>
                    {'\n'}
                    <p style={{ fontSize: '16px' }}>The ESM database is a tool developed as part of the AUDIT-TO-MEASURE project to support companies in
                    improving their energy efficiency through the implementation of Energy Saving Measures (ESMs)
                    identified during energy audits. The ESM database is a collection of energy-efficient measures that
                    have been identified as effective in reducing energy consumption in the industrial sector of the
                    European Union.</p>
                    {'\n'}
                    <p style={{ fontSize: '16px' }}>The database includes a comprehensive list of ESMs, including, measures required to implement each one, as well as the estimated energy savings, costs etc.
                    The database is designed to be user-friendly, allowing
                    companies to easily search and filter the measures based on their specific needs and requirements.
                    </p>
                        {'\n'}
                    <p style={{ fontSize: '16px' }}>The ESM database is a valuable resource for companies seeking to improve their energy efficiency, as
                    it provides a reliable source of information on effective energy-saving measures that can be
                    implemented in the industrial sector. By using the database, companies can identify the most
                    appropriate ESMs for their specific circumstances and develop a customized plan to reduce their
                    energy consumption, thereby reducing costs and improving their sustainability.
                    </p>
                </div>
                </Card>
                {/*<TotalSpent />*/}
                {/*<WeeklyRevenue />*/}
                </SimpleGrid>
            </Center>
            <SimpleGrid  gap='20px' mb='20px'>
                {/*<CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck}/>*/}
                <SimpleGrid columns={{base: 1, md: 2, xl: 2}} gap='20px'>
                    {/*<DailyTraffic />*/}
                    <Card p='20px' align='center' direction='column' w='100%'>
                     <Box w="80%" h="500px" borderWidth="1px" borderRadius="lg" overflow="hidden" p="5">
                         Min/Max/Avg (TOE) values of Primary energy savings per Industry
                         <ScorePerIndustry/>
                    </Box>
                    </Card>
                    <Card p='20px' align='center' direction='column' >
                     <Box w="90%"   borderRadius="lg" overflow="hidden" p="5">
                         Number of ESMs per Technology Group
                         <CountriesChart/>
                    </Box>
                        </Card>
                </SimpleGrid>
            </SimpleGrid>
            <SimpleGrid columns={{base: 1, md: 1, xl: 2}} gap='20px' mb='20px'>
                {/*<ComplexTable*/}
                {/*  columnsData={columnsDataComplex}*/}
                {/*  tableData={tableDataComplex}*/}
                {/*/>*/}
                <SimpleGrid columns={{base: 1, md: 2, xl: 2}} gap='20px'>
                    {/*<Tasks />*/}
                    {/*<MiniCalendar h='100%' minW='100%' selectRange={false} />*/}
                </SimpleGrid>
            </SimpleGrid>
<Footer />
        </Box>

    );
}
