import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdLock,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/home";
import ESMPage from "views/admin/dataTables";
import EsmImport from "./views/admin/esmImport";
import SignInCentered from "./views/auth/signIn/SignInCentered";
import VerificationCentered from "./views/auth/verification/VerificationCentered";
import ForgotPasswordCentered from "./views/auth/forgotPassword/ForgotPasswordCentered";
import LockCentered from "./views/auth/lock/LockCentered";
import SignUpCentered from "./views/auth/signUp/SignUpCentered";


const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/main",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  {
    name: "ESM database",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/esm",
    component: ESMPage,
  },
  {
      name: "Esm Import",
      layout: "/admin",
      path: "/importesm",
      exact: false,
      component: EsmImport,
    },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
   // --- Authentication ---
  {
    name: "Authentication",
    path: "/auth",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: "Sign In",
        path: "/sign-in",
        collapse: true,
        items: [

          {
            name: "Centered",
            layout: "/auth",
            path: "/sign-in",
            icon: (
              <Icon as={MdHome} width='16px' height='16px' color='inherit' />
            ),
            component: SignInCentered,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: "Sign Up",
        path: "/sign-up",
        collapse: true,
        items: [

          {
            name: "Centered",
            layout: "/auth",
            path: "/sign-up",
            icon: (
              <Icon as={MdHome} width='16px' height='16px' color='inherit' />
            ),
            component: SignUpCentered,
          },
        ],
      },
      // --- Verification ---
      {
        name: "Verification",
        path: "/verification",
        collapse: true,
        items: [

          {
            name: "Centered",
            layout: "/auth",
            path: "/verification",
            icon: (
              <Icon as={MdHome} width='16px' height='16px' color='inherit' />
            ),
            component: VerificationCentered,
          },
        ],
      },
      // --- Lock ---
      {
        name: "Lock",
        path: "/lock",
        collapse: true,
        items: [

          {
            name: "lock",
            layout: "/auth",
            path: "/lock",
            icon: (
              <Icon as={MdHome} width='16px' height='16px' color='inherit' />
            ),
            component: LockCentered,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: "Forgot Password",
        path: "/forgot-password",
        collapse: true,
        items: [

          {
            name: "forgot-password",
            layout: "/auth",
            path: "/forgot-password",
            icon: (
              <Icon as={MdHome} width='16px' height='16px' color='inherit' />
            ),
            component: ForgotPasswordCentered,
          },
        ],
      },
    ],
  },

];

export default routes;
